<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div v-if="isValue(name, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('resourcesStructures.Organization.name')"
        :value="createShortText(name, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="isValue(telecom, 4, mode)" class="fhir-component__body__row">
      <span>
        <b>{{ $t('resourcesStructures.Organization.telecom') }}:</b>
      </span>
      <ul
        v-for="(item, index) in telecom"
        :key="index"
        class="fhir-component__list-element"
      >
        <li
          v-if="item.system && item.value"
          class="fhir-component__list-element__list-item"
        >
          <span v-if="item.system">
            <b>{{ item.system }}:</b>
          </span>
          <span v-if="item.value">
            {{ item.value }}
          </span>
        </li>
      </ul>
    </div>
    <template v-if="address">
      <Address :address="address" :lang="lang" />
    </template>
  </div>
  <div v-else class="fhir-component__mode-0">
    <template v-if="name">
      {{ name }}
    </template>
    <MiniMode v-else :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import SingleValue from '../SingleValue/SingleValue';
import Address from '../Address/Address';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';
import mixins from '../utils/mixins';

export default {
  name: 'Organization',
  components: {
    FhirHeader,
    SingleValue,
    Address,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    name() {
      return jp.value(this.fhir, 'resource.name') || '';
    },
    telecom() {
      return jp.value(this.fhir, 'resource.telecom') || '';
    },
    address() {
      return jp.value(this.fhir, 'resource.address') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
