<template>
  <div>
    <Range
      v-if="jsonObj.valueRange"
      :json-obj="jsonObj.valueRange"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import Range from '../Range/Range';

export default {
  name: 'ValueRange',
  components: { Range },
  mixins: [mixins]
};
</script>

<style scoped></style>
