<template>
  <div class="fhir-component__list-container">
    <div class="fhir-component__list-label">
      <b>{{ label }}:</b>
    </div>
    <ul
      v-for="(data, index) in value"
      :key="index"
      class="fhir-component__list-element"
    >
      <li class="fhir-component__list-element__list-item">
        <span v-if="data.text">
          {{ createShortText(data.text, shortText, maxTextLength) }}
        </span>
        <span v-if="data.display">
          {{ createShortText(data.display, shortText, maxTextLength) }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import createShortText from '../utils/createShortText';
import mixins from '../utils/mixins';
export default {
  name: 'ListElement',
  mixins: [mixins],
  props: {
    label: { type: String, required: true },
    value: { type: [String, Array, Object], required: true },
    shortText: { default: false, type: Boolean },
    maxTextLength: { default: 10000, type: Number }
  },
  methods: {
    createShortText
  }
};
</script>
