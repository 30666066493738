<template>
  <div ref="card" :class="[!noBorders ? 'fhir-component__card' : '']">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
    >
      <slot />
    </FhirHeader>
    <template v-if="isBundle(type)">
      <div
        v-for="(item, index) in entry"
        :key="index"
        class="fhir-component__study-mode-entry"
      >
        <div class="fhir-component__img-wrapper">
          <img
            v-if="item.resource.contained"
            class="fhir-component__study-mode-image"
            :src="`data:image/png;base64,${item.resource.contained[0].content}`"
            alt="fhir-component__imaging-study-image"
          />
          <div v-else class="fhir-component__d-none" />
        </div>
        <DiagnosticReportDataInImagingStudy
          v-if="diagnosticReportDataInImagingStudy"
          :diagnostic-report-data-in-imaging-study="
            diagnosticReportDataInImagingStudy
          "
          :imaging-study-data="imagingStudyData"
          :lang="lang"
        />
        <aside v-else class="fhir-component__body__row">
          <div>
            <a
              class="fhir-component__link"
              target="_blank"
              href="#"
              @click="sourceToOutsideResourceBundle($event, item.resource)"
            >
              {{ $t('link.preview') }} ExhibeonWeb
            </a>
            <button
              class="fhir-component__link"
              @click="
                sourceToOutsideResourceBundleExhibeon3($event, item.resource)
              "
            >
              {{ $t('link.preview') }} Exhibeon3
            </button>
          </div>

          <ImagingStudyModeData
            :imaging-study-data="{
              description: null,
              modalityList: item.resource.modalityList || null,
              numberOfInstances: item.resource.numberOfInstances || null,
              numberOfSeries: item.resource.numberOfSeries || null
            }"
            :lang="lang"
          />
        </aside>
      </div>
    </template>
    <template v-else>
      <template v-if="images.length === 0">
        <div class="fhir-component__body__row">
          <div class="fhir-component__study-mode-entry">
            <div class="fhir-component__imgaging-study-wrap-img-link">
              <div class="fhir-component__img-wrapper">
                <div
                  class="fhir-component__study-mode-image fhir__component-text-center"
                >
                  <template v-if="description">
                    <div>
                      <b>{{ description }}</b>
                      aaa
                    </div>
                  </template>
                </div>
              </div>
              <div>
                <a
                  class="fhir-component__source-link fhir-component__source-link--imaging-study"
                  :href="sourceToOutsideResource"
                  target="_blank"
                  @click="handleRandCache"
                >
                  {{ $t('link.preview') }} ExhibeonWeb
                </a>
                <button
                  class="fhir-component__source-link fhir-component__source-link--imaging-study"
                  @click="handleOpenExhibeon3()"
                >
                  {{ $t('link.preview') }} Exhibeon3
                </button>
              </div>
            </div>
            <DiagnosticReportDataInImagingStudy
              v-if="diagnosticReportDataInImagingStudy"
              :diagnostic-report-data-in-imaging-study="
                diagnosticReportDataInImagingStudy
              "
              :imaging-study-data="imagingStudyData"
              :lang="lang"
            />
            <ImagingStudyModeData
              v-else
              :imaging-study-data="imagingStudyData"
              :lang="lang"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="fhir-component__study-mode-entry">
          <template v-for="(image, key) in images">
            <div v-if="image.active" :key="key" class="fh-mb">
              <div class="fhir-component__imgaging-study-wrap-img-link">
                <div class="fhir-component__img-wrapper">
                  <img
                    v-if="image.content"
                    class="fhir-component__study-mode-image"
                    :src="`data:image/png;base64,${image.content}`"
                    alt="imaging-study-image"
                    @error="onImageError($event, image)"
                  />
                  <div v-else class="fhir-component__d-none" />
                </div>
                <div>
                  <a
                    class="fhir-component__source-link fhir-component__source-link--imaging-study"
                    :href="sourceToOutsideResource"
                    target="_blank"
                    @click="handleRandCache"
                  >
                    {{ $t('link.preview') }} ExhibeonWeb
                  </a>
                  <button
                    class="fhir-component__source-link fhir-component__source-link--imaging-study"
                    @click="handleOpenExhibeon3()"
                  >
                    {{ $t('link.preview') }} Exhibeon3
                  </button>
                </div>
              </div>
            </div>
          </template>

          <DiagnosticReportDataInImagingStudy
            v-if="diagnosticReportDataInImagingStudy"
            :diagnostic-report-data-in-imaging-study="
              diagnosticReportDataInImagingStudy
            "
            :imaging-study-data="imagingStudyData"
            :lang="lang"
          />
          <ImagingStudyModeData
            v-else
            :imaging-study-data="imagingStudyData"
            :lang="lang"
            style="padding: 5px"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import jp from 'jsonpath';
import { uniqBy } from 'lodash';
import { isBundle } from './modes';
import FhirHeader from '../Appearance/FhirHeader';
import { dateTimeMoment } from '../utils/time.utils';
import mixins from '../utils/mixins';
import DiagnosticReportDataInImagingStudy from './DiagnosticReportDataInImagingStudy';
import ImagingStudyModeData from './ImagingStudyModeData';
import outsideResourceAndExhibeon3 from './mixins/outsideResourceAndExhibeon3';

export default {
  name: 'StudyMode',
  components: {
    ImagingStudyModeData,
    DiagnosticReportDataInImagingStudy,
    FhirHeader
  },
  mixins: [mixins, outsideResourceAndExhibeon3],
  props: {
    noBorders: { default: false, type: Boolean, required: false },
    diagnosticReportDataInImagingStudy: { required: false, type: Object }
  },
  computed: {
    startedDateTime() {
      const getDateTime = jp.value(this.fhir, 'resource.started') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    entry() {
      return jp.value(this.fhir, 'resource.entry') || '';
    },
    type() {
      return jp.value(this.fhir, 'resource.resourceType') || '';
    },
    contained() {
      return jp.query(this.fhir, 'resource.contained[*]') || [];
    },
    endpoint() {
      return jp.query(this.fhir, 'resource..endpoint[*]') || [];
    },
    description() {
      return jp.value(this.fhir, 'resource.description') || '';
    },
    modalityList() {
      return jp.value(this.fhir, 'resource.modalityList') || '';
    },
    numberOfInstances() {
      return jp.value(this.fhir, 'resource.numberOfInstances') || '';
    },
    numberOfSeries() {
      return jp.value(this.fhir, 'resource.numberOfSeries') || '';
    },
    images() {
      if (this.contained && this.endpoint) {
        const referencesEndpoint = this.endpoint
          .filter(item => item?.reference)
          .map(item => item?.reference.replace('#', ''));
        let res = uniqBy(
          this.contained.filter(item =>
            referencesEndpoint.some(ref => ref === item.id)
          ),
          'content'
        );
        if (res.length === 0) {
          res = referencesEndpoint;
        }
        return res.map(m => ({ ...m, active: true }));
      } else {
        return [];
      }
    },
    tenant() {
      return jp.value(
        this.fhir,
        "resource.meta.tag[?(@.system=='urn:tenant')].code"
      );
    },
    typeFhir() {
      return jp.value(this.fhir, 'resource.resourceType') || '';
    },
    id() {
      return jp.value(this.fhir, 'resource.id') || '';
    },
    imagingStudyData() {
      return {
        description: this.description || null,
        modalityList: this.modalityList || null,
        numberOfInstances: this.numberOfInstances || null,
        numberOfSeries: this.numberOfSeries || null
      };
    }
  },
  methods: {
    isBundle,
    onImageError(event, image) {
      // console.log('BŁĄD MINUIATURY',event,image)
      if (image != null) {
        image.active = false;
        this.$forceUpdate();
      }
      if (event.target != null) {
        event.target.classList.add('fhir-component__d-none');
        if (event.target.nextSibling != null) {
          event.target.nextSibling.classList.add('fhir-component__d-flex');
        }
        if (event.target.parentElement != null) {
          event.target.parentElement.classList.add(
            'fhir-component__img-wrapper-none'
          );
        }
      }
    }
  }
};
</script>

<style>
.fhir-component__img-wrapper-none {
  height: unset;
}

.fhir-component__d-none {
  display: none;
}
</style>
