<template>
  <div>
    <Attachment
      v-if="jsonObj.valueAttachment"
      :json-obj="jsonObj.valueAttachment"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import Attachment from '../Attachment/Attachment';

export default {
  name: 'ValueAttachment',
  components: { Attachment },
  mixins: [mixins]
};
</script>

<style scoped></style>
