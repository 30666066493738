export const importantDataCodesBrain = [
  'corpus callosum',
  'left-cortex-connected',
  'right-cortex-connected',
  'Left-Thalamus',
  'Right-Thalamus',
  'left-white-matter',
  'right-white-matter'
];

export function createObjectLists(jsonObject) {
  const lists = [];
  let objProper = {
    display: '',
    value: '',
    unit: '',
    coding: []
  };
  if (jsonObject && jsonObject.component) {
    jsonObject.component.map((valueComponent, index) => {
      if (valueComponent.code) {
        if (valueComponent.code.text) {
          objProper.display = valueComponent.code.text;
        } else {
          objProper.display = '';
        }
        if (
          valueComponent.valueQuantity &&
          (valueComponent.valueQuantity.value ||
            valueComponent.valueQuantity.value === 0)
        ) {
          objProper.value = valueComponent.valueQuantity.value;
        } else {
          objProper.value = '-';
        }
        if (valueComponent.valueQuantity && valueComponent.valueQuantity.unit) {
          objProper.unit = valueComponent.valueQuantity.unit;
        } else {
          objProper.unit = '-';
        }
        if (valueComponent.code.coding) {
          objProper.coding = valueComponent.code.coding;
        } else {
          objProper.coding = [];
        }
      }
      lists.push(objProper);
      objProper = {};
    });
  }
  return lists.filter(item => item.display.toLowerCase() !== 'opis');
}

export function createPairs(jsonObject) {
  const pairs = [];
  for (let i = 0; i < createObjectLists(jsonObject).length; i++) {
    for (let j = 0; j < createObjectLists(jsonObject).length; j++) {
      if (createObjectLists(jsonObject)[i].display.trim()) {
        const splitted = createObjectLists(jsonObject)
          [i].display.trim()
          .split('-');
        const findItemName = pairs.find(item => item.name === splitted[0]);

        if (findItemName) {
          if (
            findItemName.left.value === null &&
            findItemName.left.unit === null &&
            splitted[1] &&
            splitted[1].toLowerCase().trim() === 'left'
          ) {
            findItemName.left.value = createObjectLists(jsonObject)[i].value;
            findItemName.left.unit = createObjectLists(jsonObject)[i].unit;
          } else if (
            findItemName.right.value === null &&
            findItemName.right.unit === null &&
            splitted[1] &&
            splitted[1].toLowerCase().trim() === 'right'
          ) {
            findItemName.right.value = createObjectLists(jsonObject)[i].value;
            findItemName.right.unit = createObjectLists(jsonObject)[i].unit;
          }
        } else {
          pairs.push({
            name: splitted[0],
            left: {
              value:
                splitted.length > 1 &&
                splitted[1] &&
                splitted[1].toLowerCase().trim() === 'left'
                  ? createObjectLists(jsonObject)[i].value
                  : null,
              unit:
                splitted.length > 1 &&
                splitted[1] &&
                splitted[1].toLowerCase().trim() === 'left'
                  ? createObjectLists(jsonObject)[i].unit
                  : null
            },
            right: {
              value:
                splitted.length > 1 &&
                splitted[1] &&
                splitted[1].toLowerCase().trim() === 'right'
                  ? createObjectLists(jsonObject)[i].value
                  : null,
              unit:
                splitted.length > 1 &&
                splitted[1] &&
                splitted[1].toLowerCase().trim() === 'right'
                  ? createObjectLists(jsonObject)[i].unit
                  : null
            },
            value:
              splitted.length === 1
                ? createObjectLists(jsonObject)[i].value
                : null,
            unit:
              splitted.length === 1
                ? createObjectLists(jsonObject)[i].unit
                : null,
            coding: createObjectLists(jsonObject)[i].coding
          });
        }
      }
    }
  }
  return pairs;
}

export function delta(a, b) {
  return (b - a).toFixed(4);
}

export function isArrowActive(value) {
  if (value && (value > 0 || value < 0)) {
    return true;
  }
  return false;
}

export function toFixedNumber(item, number = 3) {
  return parseFloat(item) ? parseFloat(item).toFixed(number) : '-';
}
