<template>
  <span v-if="jsonObj && jsonObj.unit">[{{ jsonObj.unit }}]</span>
</template>

<script>
import mixins from '../utils/mixins';

export default {
  name: 'Quantity',
  mixins: [mixins]
};
</script>
