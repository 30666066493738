<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div
      v-if="isValue(identifier, [1, 4, 10], mode)"
      class="fhir-component__body__row"
    >
      <div
        v-for="(item, index) in identifier"
        :key="index"
        class="fhir-component__body__row"
      >
        <div v-if="item.type" class="fhir-component__body__row">
          <CodeableElement
            :label="$t('resourcesStructures.Practitioner.identifier')"
            :value="createShortText(item.type, shortText, maxTextLength)"
            :lang="lang"
          />
        </div>
      </div>
    </div>
    <div v-if="isValue(name, 2, mode)" class="fhir-component__body__row">
      <div
        v-for="(item, index) in name"
        :key="index"
        class="fhir-component__body__row"
      >
        <SingleValue
          class="fhir-component__body__row"
          :label="$t('resourcesStructures.Practitioner.firstName')"
          :value="
            createShortText(getAllNames(item.given), shortText, maxTextLength)
          "
          :lang="lang"
        />
        <SingleValue
          class="fhir-component__body__row"
          :label="$t('resourcesStructures.Practitioner.lastName')"
          :value="createShortText(item.family, shortText, maxTextLength)"
          :lang="lang"
        />
      </div>
      <div v-if="identificationNumber" class="fhir-component__body__row">
        <SingleValue
          class="fhir-component__body__row"
          :label="$t('locally.identificationNumber')"
          :value="identificationNumber"
        />
      </div>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <template v-if="names && names.length !== 0">
      <template>{{ getAllNames(names) }}</template>
      <template v-if="surname">
        {{ surname }}
      </template>
      <template v-if="identificationNumber">
        ({{ identificationNumber }})
      </template>
    </template>
    <MiniMode v-else :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import CodeableElement from '../CodeableElement/CodeableElement';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'Practitioner',
  components: {
    FhirHeader,
    CodeableElement,
    SingleValue,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    identifier() {
      return jp.value(this.fhir, 'resource.identifier') || '';
    },
    name() {
      return jp.value(this.fhir, 'resource.name') || '';
    },
    names() {
      return jp.value(this.fhir, 'resource.name[*].given') || '';
    },
    surname() {
      return jp.value(this.fhir, 'resource.name[*].family') || '';
    },
    identificationNumber() {
      return jp.value(this.fhir, 'resource.identifier[0].value') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    getAllNames(names) {
      return names.join(',');
    },
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style scoped>
@import '../assets/styles/base.css';
</style>
