<template>
  <div class="fhir-component__list-container" @click="handleEmit">
    <div v-if="label" class="fhir-component__list-label">
      <b>{{ label }}:</b>
    </div>
    <ul
      v-for="(data, index) in value"
      :key="index"
      class="fhir-component__list-element"
    >
      <li class="fhir-component__list-element__list-item">
        <CodeableConcept v-if="data" :json-obj="data" :lang="lang" />
      </li>
    </ul>
  </div>
</template>

<script>
import CodeableConcept from '../CodeableConcept/CodeableConcept';
import mixins from '../utils/mixins';

export default {
  name: 'CodeableElement',
  components: { CodeableConcept },
  mixins: [mixins],
  props: {
    label: { type: String, default: '' },
    value: { type: [String, Array, Object], required: true },
    shortText: { default: false, type: Boolean },
    maxTextLength: { default: 10000, type: Number }
  }
};
</script>

<style></style>
