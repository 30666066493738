<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>

    <table
      class="fhir__component-table-w-100 fhir__component-table fhir__components-table-make-100"
    >
      <template v-if="jsonObject && jsonObject.component">
        <tr
          v-for="(value, index) in jsonObject.component"
          :key="`laboratory-${index}`"
          :class="[
            'fhir__component-row-table fhir-component__row-pointer',
            index % 2 !== 0 ? 'fhir__component-background' : ''
          ]"
        >
          <template v-if="availableTypesDisplay(value)">
            <td class="fhir__component-row-table">
              {{
                createShortText(
                  availableTypesDisplay(value),
                  shortText,
                  maxTextLength
                )
              }}
            </td>
            <td class="fhir__component-row-table">
              <span
                class="fhir-component__fixed-col-30 fhir-component__d-inline-block fhir__component-text-center"
              >
                <Arrows
                  v-if="
                    value.interpretation &&
                      value.interpretation.coding[0] &&
                      value.interpretation.coding[0].display
                  "
                  :value="value.interpretation.coding[0].display.toLowerCase()"
                />
              </span>
              <Value
                v-if="
                  value &&
                    ((value.valueQuantity && value.valueQuantity.value) ||
                      value.valueString ||
                      value.valueDateTime ||
                      value.valueTime)
                "
                :json-obj="value"
                :short-text="shortText"
                :max-text-length="maxTextLength"
                :active-unit="false"
                :lang="lang"
              />
              <span v-else>-</span>
            </td>
            <td class="fhir__component-row-table">
              <span v-if="value.referenceRange">
                <span
                  v-for="(val, indexReferenceRange) in value.referenceRange"
                  :key="indexReferenceRange"
                >
                  <span
                    v-if="
                      val.high && val.low && val.high.value && val.low.value
                    "
                  >
                    [{{
                      createShortText(val.low.value, shortText, maxTextLength)
                    }}] - [{{
                      createShortText(val.high.value, shortText, maxTextLength)
                    }}]
                  </span>
                  <span v-else-if="val.text">
                    {{ createShortText(val.text, shortText, maxTextLength) }}
                  </span>
                </span>
              </span>
              <span v-else>
                -
              </span>
            </td>
            <td class="fhir__component-row-table">
              <Units
                v-if="value && value.valueQuantity && value.valueQuantity.unit"
                :json-obj="value.valueQuantity"
                :lang="lang"
              />
              <span v-else>
                -
              </span>
            </td>
          </template>
        </tr>
      </template>
      <template
        v-else-if="jsonObject && !jsonObject.component && jsonObject.code"
      >
        <tr
          :key="`laboratory-single-comp`"
          :class="['fhir__component-row-table fhir-component__row-pointer']"
        >
          <template v-if="availableTypesDisplay(jsonObject)">
            <td class="fhir__component-row-table">
              {{
                createShortText(
                  availableTypesDisplay(jsonObject),
                  shortText,
                  maxTextLength
                )
              }}
            </td>
            <td class="fhir__component-row-table">
              <span
                class="fhir-component__fixed-col-30 fhir-component__d-inline-block fhir__component-text-center"
              >
                <Arrows
                  v-if="
                    jsonObject.interpretation &&
                      jsonObject.interpretation.coding[0] &&
                      jsonObject.interpretation.coding[0].display
                  "
                  :value="
                    jsonObject.interpretation.coding[0].display.toLowerCase()
                  "
                />
              </span>
              <Value
                v-if="
                  jsonObject &&
                    ((jsonObject.valueQuantity &&
                      jsonObject.valueQuantity.value) ||
                      jsonObject.valueString ||
                      jsonObject.valueDateTime ||
                      jsonObject.valueTime)
                "
                :json-obj="jsonObject"
                :short-text="shortText"
                :max-text-length="maxTextLength"
                :active-unit="false"
                :lang="lang"
              />
              <span v-else>-</span>
            </td>
            <td class="fhir__component-row-table">
              <span v-if="jsonObject.referenceRange">
                <span
                  v-for="(val,
                  indexReferenceRange) in jsonObject.referenceRange"
                  :key="indexReferenceRange"
                >
                  <span
                    v-if="
                      val.high && val.low && val.high.value && val.low.value
                    "
                  >
                    [{{
                      createShortText(val.low.value, shortText, maxTextLength)
                    }}] - [{{
                      createShortText(val.high.value, shortText, maxTextLength)
                    }}]
                  </span>
                  <span v-else-if="val.text">
                    {{ createShortText(val.text, shortText, maxTextLength) }}
                  </span>
                </span>
              </span>
              <span v-else>
                -
              </span>
            </td>
            <td class="fhir__component-row-table">
              <Units
                v-if="
                  jsonObject &&
                    jsonObject.valueQuantity &&
                    jsonObject.valueQuantity.unit
                "
                :json-obj="jsonObject.valueQuantity"
                :lang="lang"
              />
              <span v-else>
                -
              </span>
            </td>
          </template>
        </tr>
      </template>
    </table>
    <div v-if="observationBodySiteCoding" class="fhir-component__body__row">
      <div
        v-for="(code,
        indexObservationBodySiteCoding) in observationBodySiteCoding"
        :key="indexObservationBodySiteCoding"
        title="Body site"
      >
        {{ createShortText(code.display, shortText, maxTextLength) }}
      </div>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment } from '../utils/time.utils';
import mixins from '../utils/mixins';
import Value from '../Value/Value';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import MiniMode from '../MiniMode/MiniMode';
import Units from '../Units/Units';
import FhirHeader from '../Appearance/FhirHeader';
import Arrows from '../Appearance/Arrows';

export default {
  name: 'ObservationLaboratory',
  components: {
    Arrows,
    FhirHeader,
    Value,
    MiniMode,
    Units
  },
  mixins: [mixins],
  data() {
    return {
      availableTypes: [
        {
          code: 'c5501',
          display: 'leukocyty'
        },
        {
          code: 'c5502',
          display: 'erytrocyty'
        },
        {
          code: 'c5503',
          display: 'hemoglobina'
        },
        {
          code: 'c5508',
          display: 'płytki krwi'
        },
        {
          code: 'c5505',
          display: 'mcv'
        },
        {
          code: 'c5506',
          display: 'mch'
        },
        {
          code: 'c5507',
          display: 'mchc'
        }
      ],
      varExists: 0
    };
  },
  computed: {
    observationEffectiveDateTime() {
      const getDateTime =
        jp.value(this.fhir, 'resource.effectiveDateTime') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    observationBodySiteCoding() {
      return jp.value(this.fhir, 'resource.bodySite.coding') || '';
    },
    observationCategoryName() {
      return jp.value(this.fhir, 'resource.category[*].coding[*].code') || '';
    },
    jsonObject() {
      return jp.value(this.fhir, 'resource') || '';
    }
  },
  methods: {
    disableOnMode,
    createShortText,
    checkIfHighReference(value) {
      if (value && value && value.high && value.high.unit && value.high.value) {
        return `${value.high.value} ${value.high.unit}`;
      } else {
        return '';
      }
    },
    checkIfLowReference(value) {
      if (value && value && value.low && value.low.unit && value.low.value) {
        return `${value.low.value} ${value.low.unit}`;
      } else {
        return '';
      }
    },
    setAmountClassColumn() {
      /*
       * This function receives arguments
       * and sets flex column name into class
       */

      if (arguments.length === 0 || arguments.length === 1) {
        return '';
      }
      if (arguments.length === 2) {
        return 'fhir-component__flex fhir-component__flex__two';
      } else if (arguments.length === 3) {
        return 'fhir-component__flex fhir-component__flex__three';
      } else if (arguments.length === 4) {
        return 'fhir-component__flex fhir-component__flex__four';
      } else if (arguments.length === 5) {
        return 'fhir-component__flex fhir-component__flex__five';
      } else {
        return '';
      }
    },
    availableTypesDisplayDefault(getItem, returned) {
      if (getItem.code?.coding?.[0]?.display?.toLowerCase()) {
        returned = getItem.code.coding[0].display;
      } else if (getItem?.code?.coding[0]?.code.toLowerCase()) {
        returned = getItem.code.coding[0].code;
      }
      return returned;
    },
    availableTypesDisplayFilter(getItem, interpretationDisplay, returned) {
      if (
        getItem?.interpretation?.coding?.[0]?.display?.toLowerCase() ===
        interpretationDisplay
      ) {
        if (getItem.code.coding[0].display.toLowerCase()) {
          returned = getItem.code.coding[0].display;
        } else if (getItem.code.coding[0].code.toLowerCase()) {
          returned = getItem.code.coding[0].code;
        }
      }
      return returned;
    },
    availableTypesDisplay(item) {
      let returned = '';

      // codetypes is a prop with string like c5501|c5502| etc...
      // codetypes have precedence before modes
      if (this.codeTypes) {
        const codeTypes = this.codeTypes.split('|');
        codeTypes.map(data => {
          if (data.toLowerCase() === item.code.coding[0].code.toLowerCase()) {
            returned = item.code.coding[0].display;
          }
        });
      } else if (this.mode === '1') {
        returned = this.availableTypesDisplayDefault(item, returned);
      } else if (this.mode === '2') {
        this.availableTypes.map(data => {
          if (
            data.display.toLowerCase() ===
            item.code.coding[0].display.toLowerCase()
          ) {
            returned = item.code.coding[0].display;
          } else if (
            data.code.toLowerCase() === item.code.coding[0].code.toLowerCase()
          ) {
            returned = item.code.coding[0].code;
          }
        });
      } else if (this.mode === '3') {
        returned = this.availableTypesDisplayFilter(item, 'high', returned);
      } else if (this.mode === '4') {
        returned = this.availableTypesDisplayFilter(item, 'low', returned);
      } else if (this.mode === '5') {
        returned = this.availableTypesDisplayFilter(item, 'normal', returned);
      } else if (this.mode === '6') {
        const high = this.availableTypesDisplayFilter(item, 'high', returned);
        const low = this.availableTypesDisplayFilter(item, 'low', returned);
        returned = high + low;
      } else {
        returned = this.availableTypesDisplayDefault(item, returned);
      }

      if (
        item.code &&
        item.code.text &&
        (item.code.text.trim().toLowerCase() === 'opis' ||
          item.code.text.trim().toLowerCase() === 'description')
      ) {
        returned = '';
      }

      return returned;
    }
  }
};
</script>

<style scoped lang="scss">
.fhir__component-table {
  margin-bottom: 6px;
  .fhir__component-th-table,
  .fhir__component-row-table {
    cursor: default;
    padding: 5px;
  }
}
.fhir__components-table-make-100 {
  table-layout: fixed;
  width: 100%;

  td {
    padding: 2px;
  }
}
</style>
