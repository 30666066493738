<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div v-if="isValue(authoredOn, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('locally.authoredOn')"
        :value="createShortText(authoredOn, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div
      v-if="isValue(occurrenceDateTime, 4, mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('locally.occurrenceDateTime')"
        :value="createShortText(occurrenceDateTime, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="category && disableOnMode([4, 10], mode)">
      <div
        v-for="(item, index) in category"
        :key="index"
        class="fhir-component__body__row"
      >
        <div v-if="item.coding">
          <CodeableElement
            :label="$t('resourcesStructures.ProcedureRequest.category')"
            :value="createShortText(item, shortText, maxTextLength)"
            :lang="lang"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isValue(priority, [4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.ProcedureRequest.priority')"
        :value="
          createShortText(
            $t(`priorities.${priority}`),
            shortText,
            maxTextLength
          )
        "
        :lang="lang"
      />
    </div>
    <div
      v-if="isValue(context.display, 4, mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.ProcedureRequest.context')"
        :value="createShortText(context.display, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <template v-if="isValue(requester, [2], mode)">
      <div class="fhir-component__body__row">
        <b>{{ $t('locally.requester') }}:</b>
        {{ requester }}
      </div>
    </template>
    <template v-if="isValue(performer, [2], mode)">
      <div class="fhir-component__body__row">
        <b>{{ $t('locally.performer') }}:</b>
        {{ performer }}
      </div>
    </template>
    <div v-if="note" class="fhir-component__body__row">
      <div class="fhir-component__list-label">
        <b>{{ $t('text.note') }}</b>
      </div>
      <ul
        v-for="(item, index) in note"
        :key="`index-${index}-note-proced-requ`"
        class="fhir-component__list-element"
      >
        <li class="fhir-component__list-element__list-item">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment } from '../utils/time.utils';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import CodeableElement from '../CodeableElement/CodeableElement';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'ServiceRequest',
  components: {
    FhirHeader,
    SingleValue,
    CodeableElement,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    status() {
      return jp.value(this.fhir, 'resource.status') || '';
    },
    intent() {
      return jp.value(this.fhir, 'resource.intent') || '';
    },
    priority() {
      return jp.value(this.fhir, 'resource.priority.code') || '';
    },
    category() {
      return jp.value(this.fhir, 'resource.category') || '';
    },
    context() {
      return jp.value(this.fhir, 'resource.context') || '';
    },
    note() {
      const getNote = jp.value(this.fhir, 'resource.note');
      if (getNote && getNote.length !== 0) {
        const list = [...getNote].map(item => item && item.text);
        return list;
      }
      return null;
    },
    authoredOn() {
      const getDateTime = jp.value(this.fhir, 'resource.authoredOn') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    occurrenceDateTime() {
      const getDateTime =
        jp.value(this.fhir, 'resource.occurrenceDateTime') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    requester() {
      const REFERENCE_NAME = 'Requester';
      const reference = jp.value(this.fhir, 'resource.requester.reference');
      const containerContained = jp.value(this.fhir, 'resource.contained');

      if (
        reference &&
        reference.length !== 0 &&
        containerContained &&
        containerContained.length !== 0
      ) {
        const foundReferencePerformer =
          reference.indexOf(REFERENCE_NAME) !== -1 ||
          reference.indexOf(`#${REFERENCE_NAME}`) !== -1
            ? reference
            : null;
        if (foundReferencePerformer) {
          const foundReferenceContained = containerContained.find(
            item =>
              item.id ===
              (foundReferencePerformer.substring(0, 1) === '#'
                ? foundReferencePerformer.substring(1)
                : foundReferencePerformer)
          );
          if (
            foundReferencePerformer &&
            foundReferenceContained &&
            foundReferenceContained.name &&
            foundReferenceContained.name[0]
          ) {
            return `${
              foundReferenceContained.name[0].family
            } ${foundReferenceContained.name[0].given.join(' ')}`;
          }
        }
      }

      return jp.value(this.fhir, 'resource.requester.reference') || '';
    },
    performer() {
      const REFERENCE_NAME = 'Practitioner';
      const performers = jp.value(this.fhir, 'resource.performer');
      const containerContained = jp.value(this.fhir, 'resource.contained');

      if (
        performers &&
        performers.length !== 0 &&
        containerContained &&
        containerContained.length !== 0
      ) {
        const foundReferencePerformer = performers.find(
          item =>
            item.reference.indexOf(REFERENCE_NAME) !== -1 ||
            item.reference.indexOf(`#${REFERENCE_NAME}`) !== -1
        ).reference;
        const foundReferenceContained = containerContained.find(
          item =>
            item.id ===
            (foundReferencePerformer.substring(0, 1) === '#'
              ? foundReferencePerformer.substring(1)
              : foundReferencePerformer)
        );
        if (
          foundReferencePerformer &&
          foundReferenceContained &&
          foundReferenceContained.name &&
          foundReferenceContained.name[0]
        ) {
          return `${
            foundReferenceContained.name[0].family
          } ${foundReferenceContained.name[0].given.join(' ')}`;
        }
      }

      return jp.value(this.fhir, 'resource.performer[0].reference') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
