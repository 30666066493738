<template>
  <ul class="fhir-component__list-element">
    <li
      v-for="(coding, index) in codingList"
      :key="index"
      class="fhir-component__list-element__list-item"
    >
      <div class="fhir-component__d-flex">
        <SingleValue
          :label="$t('documentReference.code')"
          :value="createShortText(coding.code, shortText, maxTextLength)"
          :lang="lang"
        />
        <span style="margin-right: 20px" />
        <SingleValue
          :label="$t('documentReference.display')"
          :value="createShortText(coding.display, shortText, maxTextLength)"
          :lang="lang"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import createShortText from '../utils/createShortText';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';

export default {
  name: 'List',
  components: {
    SingleValue
  },
  mixins: [mixins],
  props: {
    codingList: { required: true, type: Array }
  },
  methods: {
    createShortText
  }
};
</script>

<style scoped></style>
