<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div
      v-if="isValue(subject.display, 4, mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.MedicationAdministration.patient')"
        :value="createShortText(subject.display, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div
      v-if="isValue(status, [4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.MedicationAdministration.status')"
        :value="createShortText(status, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div
      v-if="isValue(context.display, 4, mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.MedicationAdministration.context')"
        :value="createShortText(context.display, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="contained" class="fhir-component__body__row">
      <div v-for="(item, index) in contained" :key="index">
        <div
          v-if="
            item.resourceType === 'Medication' && isValue(item.code, 4, mode)
          "
        >
          <div v-if="item.code.text">
            {{ item.code.text }}
          </div>
        </div>
      </div>
      <div
        v-if="isValue(reasonCode, 4, mode)"
        class="fhir-component__body__row"
      >
        <CodeableElement
          :label="$t('resourcesStructures.MedicationAdministration.reasonCode')"
          :value="createShortText(reasonCode, shortText, maxTextLength)"
          :lang="lang"
        />
      </div>
      <div v-if="isValue(dosage, 4, mode)">
        <div
          v-if="isValue(dosage.text, 4, mode)"
          class="fhir-component__body__row"
        >
          <SingleValue
            :label="
              $t(
                'resourcesStructures.MedicationAdministration.medicationDosage'
              )
            "
            :value="createShortText(dosage.text, shortText, maxTextLength)"
            :lang="lang"
          />
        </div>
        <div
          v-if="isValue(dosage.method, 4, mode)"
          class="fhir-component__body__row"
        >
          <div v-if="isValue(dosage.method.text, 4, mode)">
            <SingleValue
              :label="
                $t(
                  'resourcesStructures.MedicationAdministration.medicationDosageMethod'
                )
              "
              :value="
                createShortText(dosage.method.text, shortText, maxTextLength)
              "
              :lang="lang"
            />
          </div>
        </div>
        <div
          v-if="isValue(dosage.route, 4, mode)"
          class="fhir-component__body__row"
        >
          <CodeableElement
            :label="
              $t(
                'resourcesStructures.MedicationAdministration.medicationDosageRoute'
              )
            "
            :value="createShortText(dosage.route, shortText, maxTextLength)"
            :lang="lang"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isValue(medicationReferenceDisplay, 3, mode)"
      class="fhir-component__body__row"
    >
      {{ medicationReferenceDisplay }}
    </div>
    <div v-if="validityPeriodStart" class="fhir-component__body__row">
      <b>
        {{
          $t(
            'resourcesStructures.MedicationAdministration.validityPeriodStart'
          )
        }}:
      </b>
      {{ validityPeriodStart }}
    </div>
    <div v-if="validityPeriodEnd" class="fhir-component__body__row">
      <b>
        {{
          $t('resourcesStructures.MedicationAdministration.validityPeriodEnd')
        }}:
      </b>
      {{ validityPeriodEnd }}
    </div>
    <div v-if="dose" class="fhir-component__body__row">
      <b>{{ $t('resourcesStructures.Medication.dose') }}:</b>
      <span class="fh-ml">
        <template v-if="dose.value">
          {{ dose.value }}
        </template>
        <template v-if="dose.unit">
          {{ dose.unit }}
        </template>
      </span>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <template v-if="medicationAndDosage">
      {{ medicationAndDosage }}
    </template>
    <MiniMode v-else :lang="lang" :resource="resource" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment } from '../utils/time.utils';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import CodeableElement from '../CodeableElement/CodeableElement';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'MedicationAdministration',
  components: {
    FhirHeader,
    SingleValue,
    CodeableElement,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    medicationAndDosage() {
      const d = `${this.doseValue}${
        this.unit.trim().length > 0 ? ` ${this.unit}` : ''
      }`;
      return `${this.medicationReferenceDisplay}${
        d.trim().length > 0 ? ` (${d})` : ''
      }`;
    },
    subject() {
      return jp.value(this.fhir, 'resource.subject') || '';
    },
    status() {
      return jp.value(this.fhir, 'resource.status') || '';
    },
    contained() {
      return jp.value(this.fhir, 'resource.contained') || '';
    },
    context() {
      return jp.value(this.fhir, 'resource.context') || '';
    },
    contextDisplay() {
      return jp.value(this.fhir, 'resource.context.display') || '';
    },
    reasonCode() {
      return jp.value(this.fhir, 'resource.reasonCode') || '';
    },
    dosage() {
      return jp.value(this.fhir, 'resource.dosage') || '';
    },
    dosageInstruction() {
      return jp.value(this.fhir, 'resource.dosageInstruction') || '';
    },
    medicationReferenceReference() {
      return jp.value(this.fhir, 'resource.medicationReference.reference');
    },
    medicationReferenceDisplay() {
      return jp.value(this.fhir, 'resource.medicationReference.display');
    },
    dose() {
      return jp.value(this.fhir, 'resource.dosage.dose') || '';
    },
    doseValue() {
      return jp.value(this.fhir, 'resource.dosage.dose.value') || '';
    },
    unit() {
      return jp.value(this.fhir, 'resource.dosage.dose.unit') || '';
    },
    effectivePeriod() {
      const time = jp.value(this.fhir, 'resource.effectivePeriod.start');
      if (time) {
        return dateTimeMoment(time);
      } else {
        return '';
      }
    },
    effectiveDateTime() {
      const time = jp.value(this.fhir, 'resource.effectiveDateTime');
      if (time) {
        return dateTimeMoment(time);
      } else {
        return '';
      }
    },
    validityPeriodStart() {
      const time = jp.value(
        this.fhir,
        'resource.dispenseRequest.validityPeriod.start'
      );
      if (time) {
        return dateTimeMoment(time);
      } else {
        return '';
      }
    },
    validityPeriodEnd() {
      const time = jp.value(
        this.fhir,
        'resource.dispenseRequest.validityPeriod.end'
      );
      if (time) {
        return dateTimeMoment(time);
      } else {
        return '';
      }
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
