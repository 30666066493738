<template>
  <div>
    <SampledDataOrigin
      v-if="jsonObj.origin"
      :json-obj="jsonObj.origin"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
    <span v-if="sampledDataPeriod">
      {{ createShortText(sampledDataPeriod, shortText, maxTextLength) }}
    </span>
    <span v-if="sampledDataFactor">
      {{ createShortText(sampledDataFactor, shortText, maxTextLength) }}
    </span>
    <span v-if="sampledDataLowerLimit">
      {{ createShortText(sampledDataLowerLimit, shortText, maxTextLength) }}
    </span>
    <span v-if="sampledDataUpperLimit">
      {{ createShortText(sampledDataUpperLimit, shortText, maxTextLength) }}
    </span>
    <span v-if="sampledDataDimensions">
      {{ createShortText(sampledDataDimensions, shortText, maxTextLength) }}
    </span>
    <span v-if="sampledDataData">
      {{ createShortText(sampledDataData, shortText, maxTextLength) }}
    </span>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import SampledDataOrigin from './SampledDataOrigin';
import createShortText from '../utils/createShortText';

export default {
  name: 'SampledData',
  components: { SampledDataOrigin },
  mixins: [mixins],
  computed: {
    sampledDataPeriod() {
      if (this.jsonObj) {
        return this.jsonObj.period;
      } else {
        return '';
      }
    },
    sampledDataFactor() {
      if (this.jsonObj) {
        return this.jsonObj.factor;
      } else {
        return '';
      }
    },
    sampledDataLowerLimit() {
      if (this.jsonObj) {
        return this.jsonObj.lowerLimit;
      } else {
        return '';
      }
    },
    sampledDataUpperLimit() {
      if (this.jsonObj) {
        return this.jsonObj.upperLimit;
      } else {
        return '';
      }
    },
    sampledDataDimensions() {
      if (this.jsonObj) {
        return this.jsonObj.dimensions;
      } else {
        return '';
      }
    },
    sampledDataData() {
      if (this.jsonObj) {
        return this.jsonObj.data;
      } else {
        return '';
      }
    }
  },
  methods: {
    createShortText
  }
};
</script>

<style scoped></style>
