<template>
  <div v-if="isValue(contentList, 4, mode)" class="fhir-component__body__row">
    <SingleValue :label="$t('labels.attachment')" :lang="lang" />
    <ul>
      <li v-for="(content, index) in contentList" :key="index">
        <div class="fhir-component__d-flex">
          <p>
            {{ $t('documentReference.title') }}: {{ content.attachment.title }}
          </p>
          <span style="margin-right: 40px" />
          <p>
            {{ $t('documentReference.fileType') }}:
            {{ content.attachment.contentType }}
          </p>
        </div>
        <template v-if="content.attachment.data">
          <div class="fhir-component__d-flex">
            <button
              v-if="isOpenInThisTab"
              style="margin-right: 10px;"
              class="fhir-component__button-emit"
              @click="openViewer(index)"
            >
              {{ $t('documentReference.show') }}
            </button>

            <template v-if="isOpenInNewTab">
              <a
                v-if="isChromeAsBrowser"
                class="fhir-component__source-link fhir-component__source-link--document-reference"
                @click="openNewWindow(index)"
              >
                {{ $t('documentReference.openInNewTab') }}
              </a>

              <a
                v-else
                class="fhir-component__source-link fhir-component__source-link--document-reference"
                target="_blank"
                :href="
                  `data:${content.attachment.contentType};base64,${content.attachment.data}`
                "
              >
                {{ $t('documentReference.openInNewTab') }}
              </a>
            </template>

            <a
              v-if="isDownload"
              class="fhir-component__source-link fhir-component__source-link--document-reference"
              target="_blank"
              :href="
                `data:${content.attachment.contentType};base64,${content.attachment.data}`
              "
              :download="`${content.attachment.title}`"
            >
              {{ $t('documentReference.download') }}
            </a>
          </div>
        </template>
        <template v-else>
          <a
            class="fhir-component__source-link fhir-component__source-link--document-reference"
            target="_blank"
            :href="content.attachment.url"
            :download="`${content.attachment.title}`"
          >
            {{ $t('documentReference.open') }}
          </a>
        </template>
        <hr />
      </li>
    </ul>

    <!--Viewer-->
    <button
      v-if="isViewerOpen"
      style="margin-right: 10px;"
      class="fhir-component__button-emit"
      @click="closeViewer"
    >
      {{ $t('documentReference.close') }}
    </button>

    <div v-if="isViewerOpen" class="fhir-component__wrapper__iframe">
      <template v-if="attachmentContentType && attachmentData">
        <iframe
          class="fhir-component__iframe"
          :src="`data:${attachmentContentType};base64,${attachmentData}`"
        />
      </template>

      <template v-else>
        {{ $t('documentReference.weAreTryingToShowYou') }} {{ attachmentTitle }}
      </template>
    </div>
  </div>
</template>

<script>
import createShortText from '../utils/createShortText';
import SingleValue from '../SingleValue/SingleValue';
import mixins from '../utils/mixins';
import disableOnMode from '../utils/disableOnMode';

export default {
  name: 'AttachmentViewer',
  components: {
    SingleValue
  },
  mixins: [mixins],
  props: {
    contentList: { required: true, type: [Array, String] },
    isDownload: { default: true, type: Boolean },
    isOpenInNewTab: { default: true, type: Boolean },
    isOpenInThisTab: { default: true, type: Boolean },
    isViewer: { default: true, type: Boolean }
  },
  data() {
    return {
      isViewerOpen: this.isViewer,
      indexInContentList: 0
    };
  },
  computed: {
    isChromeAsBrowser() {
      return navigator.userAgent.indexOf('Chrome') !== -1;
    },
    attachmentData() {
      return this.contentList[this.indexInContentList].attachment.data || '';
    },
    attachmentContentType() {
      return (
        this.contentList[this.indexInContentList].attachment.contentType || ''
      );
    },
    attachmentTitle() {
      return this.contentList[this.indexInContentList].attachment.title || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    },
    openViewer(index) {
      this.isViewerOpen = true;
      this.indexInContentList = index;
    },
    closeViewer() {
      this.isViewerOpen = false;
    },
    openNewWindow(index) {
      this.indexInContentList = index;
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${this.attachmentData}'></iframe>`
      );
    }
  }
};
</script>

<style scoped></style>
