<template>
  <div>
    <span v-if="ratioNumerator">
      <Quantity
        v-if="ratioNumerator"
        :json-obj="ratioNumerator"
        :short-text="shortText"
        :max-text-length="maxTextLength"
      />
    </span>
    <span v-if="ratioDenominator">
      <Quantity
        v-if="ratioDenominator"
        :json-obj="ratioDenominator"
        :short-text="shortText"
        :max-text-length="maxTextLength"
      />
    </span>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import Quantity from '../Quantity/Quantity';

export default {
  name: 'Ratio',
  components: { Quantity },
  mixins: [mixins],
  computed: {
    ratioNumerator() {
      if (this.jsonObj) {
        return this.jsonObj.numerator;
      } else {
        return '';
      }
    },
    ratioDenominator() {
      if (this.jsonObj) {
        return this.jsonObj.denominator;
      } else {
        return '';
      }
    }
  }
};
</script>
