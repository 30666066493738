
import { defineComponent, computed, PropType } from 'vue';
import { getLang } from '@/components/Commons/AccessibilityMenu/AccessibilityActions';
import Patient from '@/components/FhirComponents/Patient/Patient.vue';
import i18nGlobal from '@/lang/index';
import Organization from '@/components/FhirComponents/Organization/Organization.vue';
import Encounter from '@/components/FhirComponents/Encounter/Encounter.vue';
import DiagnosticReport from '@/components/FhirComponents/DiagnosticReport/DiagnosticReport.vue';
import EpisodeOfCare from '@/components/FhirComponents/EpisodeOfCare/EpisodeOfCare.vue';
import MedicationRequest from '@/components/FhirComponents/MedicationRequest/MedicationRequest.vue';
import MedicationAdministration from '@/components/FhirComponents/MedicationAdministration/MedicationAdministration.vue';
import ImagingStudy from '@/components/FhirComponents/ImagingStudy/ImagingStudy.vue';
import Observation from '@/components/FhirComponents/Observation/Observation.vue';
import DocumentReference from '@/components/FhirComponents/DocumentReference/DocumentReference.vue';
import Practitioner from '@/components/FhirComponents/Practitioner/Practitioner.vue';
import Procedure from '@/components/FhirComponents/Procedure/Procedure.vue';
import ProcedureRequest from '@/components/FhirComponents/ProcedureRequest/ProcedureRequest.vue';
import ServiceRequest from '@/components/FhirComponents/ServiceRequest/ServiceRequest.vue';
import Condition from '@/components/FhirComponents/Condition/Condition.vue';
import Specimen from '@/components/FhirComponents/Specimen/Specimen.vue';
import Consent from '@/components/FhirComponents/Consent/Consent.vue';
import Medication from '@/components/FhirComponents/Medication/Medication.vue';
import AllergyIntolerance from '@/components/FhirComponents/AllergyIntolerance/AllergyIntolerance.vue';
import useUser from '@/composables/useUser';

export default defineComponent({
  name: 'FhirComponentsRouter',
  emits: ['get-active-header'],
  components: {
    AllergyIntolerance,
    Medication,
    Consent,
    Specimen,
    Condition,
    ServiceRequest,
    ProcedureRequest,
    Procedure,
    Practitioner,
    DocumentReference,
    Observation,
    ImagingStudy,
    MedicationAdministration,
    MedicationRequest,
    EpisodeOfCare,
    DiagnosticReport,
    Encounter,
    Organization,
    Patient
    //FhirFormsRouter: () => import('../forms/FhirFormsRouter'),
  },
  props: {
    resource: {
      type: Object as PropType<any>,
      required: false
    },
    resources: {
      type: Object as PropType<any[]>,
      required: false
    },
    lang: {
      type: String,
      required: false
    },
    mode: {
      type: Number,
      required: false,
      default: 1
    },
    token: {
      type: String,
      required: false
    },
    hideForm: {
      type: Boolean,
      required: false,
      default: false
    },
    showTags: {
      type: Boolean,
      required: false,
      default: true
    },
    editTags: {
      type: Boolean,
      required: false,
      default: false
    },
    forceViewer: { default: false, type: Boolean, required: false }
  },
  setup(props) {
    const finalToken = computed(() => props.token || useUser().getToken());
    const finalDisplay = computed(() => {
      return (props.resource as any)?.resourceType;
    });
    const language = computed(() => props.lang || getLang() || 'pl');
    const finalMode = computed(() => props.mode);

    const i18n = computed(() => i18nGlobal);

    return {
      finalToken,
      finalDisplay,
      language,
      finalMode,
      i18n
    };
  }
});
