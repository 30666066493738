<template>
  <span>
    <span v-if="(quantityValue && displays.value) || displays.value === 0">
      {{ createShortText(quantityValue, shortText, maxTextLength) }}
    </span>
    <span v-if="quantityUnit && displays.unit && activeUnit">
      {{ createShortText(quantityUnit, shortText, maxTextLength) }}
    </span>
    <span v-if="quantityComparator && displays.comparator">
      {{ createShortText(quantityComparator, shortText, maxTextLength) }}
    </span>
    <span v-if="quantitySystem && displays.system">
      {{ createShortText(quantitySystem, shortText, maxTextLength) }}
    </span>
    <span v-if="quantityCode && displays.code">
      {{ createShortText(quantityCode, shortText, maxTextLength) }}
    </span>
  </span>
</template>

<script>
import mixins from '../utils/mixins';
import createShortText from '../utils/createShortText';

export default {
  name: 'Quantity',
  mixins: [mixins],
  props: {
    activeUnit: { type: Boolean, required: false, default: true },
    maxTwoDigitsAfterDot: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      displays: {
        value: true,
        unit: true,
        comparator: false,
        system: false,
        code: false
      }
    };
  },
  computed: {
    quantityValue() {
      if (this.jsonObj) {
        if (this.maxTwoDigitsAfterDot) {
          return Math.floor(this.jsonObj.value * 100) / 100;
        } else {
          return this.jsonObj.value.toString();
        }
      } else {
        return '';
      }
    },
    quantityUnit() {
      if (this.jsonObj) {
        return this.jsonObj.unit;
      } else {
        return '';
      }
    },
    quantityComparator() {
      if (this.jsonObj) {
        return this.jsonObj.comparator;
      } else {
        return '';
      }
    },
    quantitySystem() {
      if (this.jsonObj) {
        return this.jsonObj.system;
      } else {
        return '';
      }
    },
    quantityCode() {
      if (this.jsonObj) {
        return this.jsonObj.code;
      } else {
        return '';
      }
    }
  },
  methods: {
    createShortText
  }
};
</script>
