<template>
  <div>
    <StudyMode
      v-if="isStudy(mode)"
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :diagnostic-report-data-in-imaging-study="
        diagnosticReportDataInImagingStudy
      "
    >
      <slot />
    </StudyMode>
    <SeriesMode
      v-if="isSeries(mode)"
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :diagnostic-report-data-in-imaging-study="
        diagnosticReportDataInImagingStudy
      "
    >
      <slot />
    </SeriesMode>
    <ViewerMode
      v-if="isView(mode)"
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :diagnostic-report-data-in-imaging-study="
        diagnosticReportDataInImagingStudy
      "
    >
      <slot />
    </ViewerMode>
    <template v-if="isMinimal(mode)">
      <div v-if="!disableOnMode(0, mode)" class="fhir-component__mode-0">
        <MiniMode :lang="lang" :resource="resource" />
      </div>
    </template>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import StudyMode from './StudyMode';
import SeriesMode from './SeriesMode';
import ViewerMode from './ViewerMode';
import { isStudy, isSeries, isView, isMinimal } from './modes';
import MiniMode from '../MiniMode/MiniMode';
import disableOnMode from '../utils/disableOnMode';

export default {
  name: 'ImagingStudy',
  components: {
    StudyMode,
    SeriesMode,
    ViewerMode,
    MiniMode
  },
  mixins: [mixins],
  props: {
    diagnosticReportDataInImagingStudy: {
      required: false,
      type: Object
    },
    forceViewer: { default: false, type: Boolean, required: false }
  },
  methods: {
    disableOnMode,
    isStudy,
    isSeries,
    isView,
    isMinimal
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
>
