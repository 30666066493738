<template>
  <span
    v-if="value === 'high'"
    :class="[
      'fhir-component__arrow--up',
      arrowHighYellow ? 'fhir-component__arrow--yellow' : ''
    ]"
  >
    <span class="visuallyhidden" />
  </span>
  <span v-else-if="value === 'low'" class="fhir-component__arrow--down">
    <span class="visuallyhidden" />
  </span>
  <span v-else class="fhir-component__empty-char" />
</template>

<script>
export default {
  name: 'Arrows',
  props: {
    value: { required: true, type: String, default: '' },
    arrowHighYellow: { required: false, type: Boolean, default: false }
  }
};
</script>

<style scoped></style>
