import axios from 'axios';

const outsideResourceAndExhibeon3 = {
  data() {
    return {
      noCacheRand: Math.random()
        .toString(36)
        .substring(7)
    };
  },
  computed: {
    sourceToOutsideResource() {
      const isWebComponent = document.querySelector('fhir-imaging-study');
      if (!isWebComponent) {
        if (this.tenant && this.typeFhir && this.id) {
          return `${process.env.VUE_APP_IMAGING_STUDY_IMSPROXY_URL}/${this.tenant}/${this.typeFhir}/${this.id}`;
        } else {
          return '#';
        }
      } else if (isWebComponent && isWebComponent.shadowRoot) {
        if (this.tenant && this.typeFhir && this.id) {
          return `${process.env.VUE_APP_IMAGING_STUDY_IMSPROXY_URL_WITHOUT_HOST}/${this.tenant}/${this.typeFhir}/${this.id}`;
        } else {
          return '#';
        }
      } else {
        return '#';
      }
    }
  },
  methods: {
    sourceToOutsideResourceBundle($event, resource) {
      const tenant = resource.meta.tag[0].code;
      const type = resource.resourceType;
      const id = resource.id;
      const isWebComponent = document.querySelector('fhir-imaging-study');

      if (!isWebComponent) {
        if (tenant && type && id) {
          $event.target.href = `${process.env.VUE_APP_IMAGING_STUDY_IMSPROXY_URL}/${tenant}/${type}/${id}`;
        } else {
          $event.target.href = '#';
        }
      } else if (isWebComponent && isWebComponent.shadowRoot) {
        if (tenant && type && id) {
          $event.target.href = `${process.env.VUE_APP_IMAGING_STUDY_IMSPROXY_URL_WITHOUT_HOST}/${tenant}/${type}/${id}`;
        } else {
          $event.target.href = '#';
        }
      } else {
        $event.target.href = '#';
      }
    },
    sourceToOutsideResourceBundleExhibeon3($event, resource) {
      const id = resource.id;

      if (id) {
        this.handleOpenExhibeon3(id);
      }
    },
    async handleOpenExhibeon3(bundleId) {
      this.handleRandCache();

      // jezeli bundle to ustawiam id z bundla
      const resourceId = bundleId || this.id;
      const url = `${
        process.env.VUE_APP_FHIR_COMP_EXHIBEON_3_URL
      }/?imsUrl=${encodeURIComponent(
        process.env.VUE_APP_IMAGING_STUDY_IMSPROXY_URL
      )}&stuInsUID=${resourceId}&token=${this.token}&noCacheRand=${
        this.noCacheRand
      }`;

      const config = {};
      if (this.token) {
        config['headers'] = { Authorization: `Bearer ${this.token}` };
      }

      await axios.get(url, config);
    },
    handleRandCache() {
      this.noCacheRand = Math.random()
        .toString(36)
        .substring(7);
    }
  }
};

export default outsideResourceAndExhibeon3;
