<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <template v-if="jsonObject.component">
      <template
        v-for="(value, indexComponent) in jsonObject.component"
        :key="indexComponent"
      >
        <div
          :class="[
            'fhir-component__body__row',
            setAmountClassColumn(value, fhir, value.interpretation),
            indexComponent % 2 !== 0 ? 'fhir__component-background' : ''
          ]"
        >
          <Value
            v-if="value"
            :json-obj="value"
            :short-text="shortText"
            :max-text-length="maxTextLength"
            :lang="lang"
          />
          <CodeableConcept
            v-if="value.interpretation"
            :json-obj="value.interpretation"
            :title="value.interpretation"
            :short-text="shortText"
            :max-text-length="maxTextLength"
            :lang="lang"
          />
          <span v-if="value.code">
            <span v-for="(valueCode, indexCode) in value.code" :key="indexCode">
              <span v-if="valueCode[0].display">
                {{
                  createShortText(
                    valueCode[0].display,
                    shortText,
                    maxTextLength
                  )
                }}
              </span>
              <span v-else-if="valueCode[0].code">
                {{
                  createShortText(valueCode[0].code, shortText, maxTextLength)
                }}
              </span>
              <span v-else>
                <DisplayCoding
                  v-if="fhir"
                  :resource="resource"
                  :json-obj="fhir"
                  :short-text="shortText"
                  :max-text-length="maxTextLength"
                  :lang="lang"
                />
              </span>
            </span>
          </span>
        </div>
      </template>
    </template>
    <div v-if="observationBodySiteCoding" class="fhir-component__body__row">
      <div
        v-for="(code,
        indexObservationBodySiteCoding) in observationBodySiteCoding"
        :key="indexObservationBodySiteCoding"
        title="Body site"
      >
        <span v-if="code.display">
          {{ createShortText(code.display, shortText, maxTextLength) }}
        </span>
      </div>
    </div>
    <!--If component is null-->
    <template v-if="!jsonObject.component">
      <div
        :class="[
          'fhir-component__body__row',
          setAmountClassColumn(jsonObject.valueQuantity, fhir)
        ]"
      >
        <Value
          v-if="fhir.resource"
          :json-obj="fhir.resource"
          :short-text="shortText"
          :max-text-length="maxTextLength"
          :lang="lang"
        />
        <CodeableConcept
          v-if="jsonObject.interpretation"
          :json-obj="jsonObject.interpretation"
          title="Interpretation Text"
          :short-text="shortText"
          :max-text-length="maxTextLength"
          :lang="lang"
        />
        <span v-if="jsonObject.code">
          <span v-if="jsonObject.code[0] && jsonObject.code[0].display">
            {{
              createShortText(
                jsonObject.code[0].display,
                shortText,
                maxTextLength
              )
            }}
          </span>
          <span v-else-if="jsonObject.code[0] && jsonObject.code[0].code">
            {{
              createShortText(jsonObject.code[0].code, shortText, maxTextLength)
            }}
          </span>
          <span v-else>
            <DisplayCoding
              v-if="fhir"
              :resource="resource"
              :json-obj="fhir"
              :short-text="shortText"
              :max-text-length="maxTextLength"
              :lang="lang"
            />
          </span>
        </span>
      </div>
      <div v-if="jsonObject.referenceRange" class="fhir-component__body__row">
        <div
          v-for="(value, indexReferenceRange) in jsonObject.referenceRange"
          :key="indexReferenceRange"
        >
          <div v-if="checkIfHighReference(value)" title="High Reference Range">
            {{
              createShortText(
                checkIfHighReference(value),
                shortText,
                maxTextLength
              )
            }}
          </div>
          <div v-if="checkIfLowReference(value)" title="Low Reference Range">
            {{
              createShortText(
                checkIfLowReference(value),
                shortText,
                maxTextLength
              )
            }}
          </div>
        </div>
      </div>
      <div v-if="observationBodySiteCoding" class="fhir-component__body__row">
        <div
          v-for="(code,
          indexObservationBodySiteCoding) in observationBodySiteCoding"
          :key="indexObservationBodySiteCoding"
          title="Body site"
        >
          <span v-if="code.display">
            {{ createShortText(code.display, shortText, maxTextLength) }}
          </span>
        </div>
      </div>
    </template>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :lang="lang" :resource="resource" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import mixins from '../utils/mixins';
import Value from '../Value/Value';
import CodeableConcept from '../CodeableConcept/CodeableConcept';
import DisplayCoding from '../DisplayCoding/DisplayCoding';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'ObservationImaging',
  components: {
    FhirHeader,
    Value,
    CodeableConcept,
    DisplayCoding,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    observationEffectiveDateTime() {
      return jp.value(this.fhir, 'resource.effectiveDateTime') || '';
    },
    observationBodySiteCoding() {
      return jp.value(this.fhir, 'resource.bodySite.coding') || '';
    },
    observationCategoryName() {
      return jp.value(this.fhir, 'resource.category[*].coding[*].code') || '';
    },
    jsonObject() {
      return jp.value(this.fhir, 'resource') || '';
    }
  },
  methods: {
    disableOnMode,
    createShortText,
    checkIfHighReference(value) {
      if (value && value && value.high && value.high.unit && value.high.value) {
        console.log(`${value.high.value} ${value.high.unit}`);
        return `${value.high.value} ${value.high.unit}`;
      } else {
        return '';
      }
    },
    checkIfLowReference(value) {
      if (value && value && value.low && value.low.unit && value.low.value) {
        console.log(`${value.low.value} ${value.low.unit}`);
        return `${value.low.value} ${value.low.unit}`;
      } else {
        return '';
      }
    },
    setAmountClassColumn() {
      /*
       * This function receives arguments
       * and sets flex column name into class
       */

      if (arguments.length === 0 || arguments.length === 1) {
        return '';
      }
      if (arguments.length === 2) {
        return 'fhir-component__flex fhir-component__flex__two';
      } else if (arguments.length === 3) {
        return 'fhir-component__flex fhir-component__flex__three';
      } else if (arguments.length === 4) {
        return 'fhir-component__flex fhir-component__flex__four';
      } else if (arguments.length === 5) {
        return 'fhir-component__flex fhir-component__flex__five';
      } else {
        return '';
      }
    }
  }
};
</script>

<style scoped></style>
