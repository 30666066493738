<template>
  <div>
    <template v-if="description">
      <div>
        <b>{{ description }}</b>
      </div>
    </template>
    <template v-if="modalityList" class="fhir-component__body__row">
      <ListElement
        :label="$t('resourcesStructures.ImagingStudy.modality')"
        :value="modalityList"
        :lang="lang"
      />
    </template>
    <div v-if="numberOfInstances" class="fhir-component__body__row">
      <SingleValue
        ref="numberOfInstancesRef"
        :label="$t('labels.numberInstances')"
        :value="numberOfInstances"
        :lang="lang"
      />
    </div>
    <div v-if="numberOfSeries" class="fhir-component__body__row">
      <SingleValue
        ref="numberOfSeriesRef"
        :label="$t('labels.numberSeries')"
        :value="numberOfSeries"
        :lang="lang"
      />
    </div>
  </div>
</template>

<script>
import ListElement from '../ListElement/ListElement';
import SingleValue from '../SingleValue/SingleValue';
import mixins from '../utils/mixins';
export default {
  name: 'ImagingStudyModeData',
  components: { SingleValue, ListElement },
  mixins: [mixins],
  props: {
    imagingStudyData: {
      required: false,
      type: Object
    }
  },
  computed: {
    description() {
      return this.imagingStudyData.description || null;
    },
    numberOfInstances() {
      return this.imagingStudyData.numberOfInstances || null;
    },
    numberOfSeries() {
      return this.imagingStudyData.numberOfSeries || null;
    },
    modalityList() {
      return this.imagingStudyData.modalityList || null;
    }
  }
};
</script>

<style scoped></style>
