<template>
  <span>
    <span v-for="(value, index) in getCodings" :key="index" title="codes">
      {{ createShortText(value, shortText, maxTextLength) }}
    </span>
  </span>
</template>

<script>
import mixins from '../utils/mixins';
import { useI18n } from 'vue-i18n';
import createShortText from '../utils/createShortText';
import patientI18n from '@/lang/lang/pl/patient';
import { camelCaseSentence } from '@/utils/utils.ts';

export default {
  name: 'Codings',
  mixins: [mixins],
  props: {
    displayAndCoding: { required: false, default: 'false', type: String },
    shortText: { default: false, type: Boolean },
    maxTextLength: { default: 10000, type: Number }
  },
  data() {
    return {};
  },
  computed: {
    getCodings() {
      const { t } = useI18n();
      if (Array.isArray(this.jsonObj)) {
        return this.jsonObj.map(item => {
          if (item.display && item.code && this.displayAndCoding === 'true') {
            return `${item.code} - ${item.display}`;
          } else if (item.display) {
            const camelCased = camelCaseSentence(item.display);
            const results = patientI18n.results;
            if (camelCased in results) {
              return t(`patient.results.${camelCased}`);
            } else {
              return item.display;
            }
          } else {
            return '';
          }
        });
      } else if (this.jsonObj) {
        return this.jsonObj;
      } else {
        return '';
      }
    }
  },
  methods: {
    createShortText
  }
};
</script>

<style scoped></style>
