<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div v-if="form" class="fhir-component__body__row">
      <CodeableElement
        ref="codeableConceptRef"
        :label="$t('resourcesStructures.Medication.form')"
        :value="form"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div>
    <div v-if="ingredient">
      <div v-for="(item, index) in ingredient" :key="index">
        <SingleValue
          :label="$t('resourcesStructures.Medication.dose')"
          :value="getAmount(item.amount.numerator)"
          :short-text="shortText"
          :max-text-length="maxTextLength"
          class="fhir-component__body__row"
          :lang="lang"
        />
        <SingleValue
          :label="$t('resourcesStructures.Medication.amount')"
          :value="getAmount(item.amount.denominator)"
          :short-text="shortText"
          :max-text-length="maxTextLength"
          class="fhir-component__body__row"
          :lang="lang"
        />
      </div>
    </div>
    <div v-if="contained">
      <div
        v-for="(item, index) in contained"
        :key="index"
        class="fhir-component__body__row"
      >
        <div v-if="item.resourceType === 'Organization'">
          <SingleValue
            :label="$t('resourcesStructures.Medication.manufacturer')"
            :value="item.name"
            :short-text="shortText"
            :max-text-length="maxTextLength"
            :lang="lang"
          />
        </div>
        <div v-if="item.resourceType === 'Substance'">
          <CodeableElement
            :label="$t('resourcesStructures.Medication.ingredient')"
            :value="item.code"
            :short-text="shortText"
            :max-text-length="maxTextLength"
            :lang="lang"
          />
        </div>
      </div>
    </div>
    <div
      v-if="container && disableOnMode(2, mode)"
      class="fhir-component__body__row"
    >
      <CodeableElement
        ref="containerRef"
        :label="$t('resourcesStructures.Medication.package')"
        :value="container"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div>
    <div
      v-if="batch && disableOnMode(2, mode)"
      class="fhir-component__body__row"
    >
      <div v-for="(item, index) in batch" :key="`lot-${index}`">
        <span>
          <b>{{ $t('resourcesStructures.Medication.batch-lotNumber') }}</b>
        </span>
        <div>
          {{ createShortText(item.lotNumber, shortText, maxTextLength) }}
        </div>
      </div>
      <div v-for="(item, index) in batch" :key="`date-${index}`">
        <span>
          <b>{{ $t('resourcesStructures.Medication.batch-expirationDate') }}</b>
        </span>
        <div>{{ item.expirationDate }}</div>
      </div>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import CodeableElement from '../CodeableElement/CodeableElement';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'Medication',
  components: {
    FhirHeader,
    CodeableElement,
    SingleValue,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    form() {
      return jp.value(this.fhir, 'resource.form') || '';
    },
    ingredient() {
      return jp.value(this.fhir, 'resource.ingredient') || '';
    },
    batch() {
      return jp.value(this.fhir, 'resource.package.batch') || '';
    },
    contained() {
      return jp.value(this.fhir, 'resource.contained') || '';
    },
    container() {
      return jp.value(this.fhir, 'resource.package.container') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    getAmount(item) {
      return `${item.value} ${item.code}`;
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
