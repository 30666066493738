<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <!--description-->
    <div v-if="isValue(description, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('labels.description')"
        :value="createShortText(description, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <!--date-->
    <div v-if="isValue(date, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('forms.pureDate')"
        :value="createShortText(date, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <!--typeCodingList-->
    <div
      v-if="isValue(typeCodingList, 4, mode)"
      class="fhir-component__body__row"
    >
      <div class="fhir-component__list-label">
        <SingleValue :label="$t('documentReference.typeCoding')" :lang="lang" />
      </div>
      <List :coding-list="typeCodingList" :lang="lang" />
    </div>
    <!--categoryCodingList-->
    <div
      v-if="isValue(categoryList, 4, mode)"
      class="fhir-component__body__row"
    >
      <div class="fhir-component__list-label">
        <SingleValue
          :label="$t('documentReference.categoryCoding')"
          :lang="lang"
        />
      </div>
      <div
        v-for="(category, index) in categoryList"
        :key="`categoryList--${index}`"
      >
        <List :coding-list="category.coding" :lang="lang" />
      </div>
    </div>
    <!--contentList-->
    <AttachmentViewer :content-list="contentList" :lang="lang" />
  </div>
  <!--Mini mode-->
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';
import List from './List';
import AttachmentViewer from './AttachmentViewer';

export default {
  name: 'DocumentReference',
  components: {
    AttachmentViewer,
    List,
    FhirHeader,
    SingleValue,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    typeCodingList() {
      return jp.value(this.fhir, 'resource.type.coding') || '';
    },
    categoryList() {
      return jp.value(this.fhir, 'resource.category') || '';
    },
    contentList() {
      return jp.value(this.fhir, 'resource.content') || '';
    },
    date() {
      const d = new Date(jp.value(this.fhir, 'resource.date') || '');
      return (
        d.getDate() + '/' + this.getMonth(d.getMonth()) + '/' + d.getFullYear()
      );
    },
    description() {
      return jp.value(this.fhir, 'resource.description') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    },
    getMonth(value) {
      value += 1;
      if (value < 10) return '0' + value;
      else return value;
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
