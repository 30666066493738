<template>
  <div>
    <SampledData
      v-if="jsonObj.valueSampledData"
      :json-obj="jsonObj.valueSampledData"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import SampledData from '../SampledData/SampledData';

export default {
  name: 'ValueSampledData',
  components: { SampledData },
  mixins: [mixins]
};
</script>

<style scoped></style>
