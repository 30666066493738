<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div v-if="isValue(careManager, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('resourcesStructures.EpisodeOfCare.episodeCareManager')"
        :value="createShortText(careManager, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <Period
      v-if="period"
      class="period"
      :json-obj="period"
      :fhir="resource"
      :lang="lang"
    />
    <div v-if="statusHistory">
      <p class="fhir-component__body__row">
        <b>
          {{ $t('resourcesStructures.EpisodeOfCare.episodeStatusHistory') }}:
        </b>
      </p>
      <div
        v-for="(item, index) in statusHistory"
        :key="index"
        class="fhir-component__body__row"
      >
        <span>{{ index + 1 }}.</span>
        <span>Status: {{ item.status }}</span>
        <Period
          v-if="item.period"
          class="period"
          :json-obj="item.period"
          :fhir="resource"
          :lang="lang"
        />
      </div>
    </div>
    <div
      v-if="isValue(referralRequest, 4, mode)"
      class="fhir-component__body__row"
    >
      <ListElement
        :label="$t('resourcesStructures.EpisodeOfCare.episodeReferralRequest')"
        :value="createShortText(referralRequest, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="diagnosis">
      <div
        v-for="(item, index) in diagnosis"
        :key="index"
        class="fhir-component__body__row"
      >
        <div
          v-if="isValue(item.role, 4, mode)"
          class="fhir-component__body__row"
        >
          <CodeableElement
            :label="$t('resourcesStructures.EpisodeOfCare.diagnosis')"
            :value="createShortText(item.role, shortText, maxTextLength)"
            :lang="lang"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :lang="lang" :resource="resource" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import ListElement from '../ListElement/ListElement';
import CodeableElement from '../CodeableElement/CodeableElement';
import Period from '../Period/Period';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'EpisodeOfCare',
  components: {
    FhirHeader,
    SingleValue,
    ListElement,
    CodeableElement,
    Period,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    status() {
      return jp.value(this.fhir, 'resource.status') || '';
    },
    statusHistory() {
      return jp.value(this.fhir, 'resource.statusHistory') || '';
    },
    diagnosis() {
      return jp.value(this.fhir, 'resource.diagnosis') || '';
    },
    period() {
      return jp.value(this.fhir, 'resource.period');
    },
    referralRequest() {
      return jp.value(this.fhir, 'resource.referralRequest') || '';
    },
    careManager() {
      return jp.value(this.fhir, 'resource.careManager.display') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
