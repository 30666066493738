<template>
  <table class="fhir__component-table-w-100 fhir__component-table">
    <tr>
      <th
        class="fhir__component-text-center fhir__component-th-table fhir-component__width-30-percent"
      >
        {{ $t('resourcesStructures.Endpoint.name') }}
      </th>
      <th class="fhir__component-text-center fhir__component-th-table">
        {{ $t('labelsRes.value') }}
      </th>
      <th
        style="width: 100px;"
        class="fhir__component-text-center fhir__component-th-table"
      >
        {{ $t('labelsRes.unit') }}
      </th>
    </tr>
    <tr
      v-for="(itemType, indexType) in displayTypes"
      :key="`${indexType}-${itemType.name}`"
      :class="[
        'fhir__component-row-table fhir-component__row-pointer',
        indexType % 2 !== 0 ? 'fhir__component-background' : '',
        rowHighlight(itemType.name, 'bg')
      ]"
      @click="selectRow(itemType.name)"
    >
      <td
        :class="[
          'fhir__component-row-table',
          rowHighlight(itemType.name, 'indicator')
        ]"
      >
        {{
          clearStringToTranslate(itemType.code, 'brainStructure') ||
            itemType.name
        }}
      </td>
      <td class="fhir__component-row-table">
        {{ getItemValue(getAllWithoutPairs, itemType.name) }}
      </td>
      <td class="fhir__component-row-table">
        {{ getItemUnit(getAllWithoutPairs, itemType.name) }}
      </td>
    </tr>
  </table>
</template>

<script>
import mixins from '../utils/mixins';
import clearStringToTranslate from '../utils/clearStringToTranslate';

export default {
  name: 'VolumeBrainTableGeneral',
  mixins: [mixins],
  props: {
    displayTypes: { required: true, default: () => [], type: Array },
    getAllWithoutPairs: { required: true, default: () => [], type: Array },
    selectedItem: { required: false, default: null, type: String }
  },
  methods: {
    clearStringToTranslate,
    getItemObj(lists, name) {
      return lists.find(item => item.name === name && item.value);
    },
    getItemValue(lists, name) {
      return this.getItemObj(lists, name).value.toFixed(4);
    },
    getItemUnit(lists, name) {
      return this.getItemObj(lists, name).unit;
    },
    selectRow(itemType) {
      this.$emit('get-selected-item', itemType);
    },
    rowHighlight(name, type) {
      if (type === 'indicator') {
        return this.selectedItem === name
          ? 'fhir-component__row-selected-indicator'
          : '';
      }
      if (type === 'bg') {
        return this.selectedItem === name
          ? 'fhir-component__row-selected-bg'
          : '';
      }
      return this.selectedItem === name
        ? 'fhir-component__row-selected-indicator'
        : '';
    }
  }
};
</script>

<style scoped lang="scss">
.fhir__component-table {
  margin-bottom: 6px;
  .fhir__component-th-table,
  .fhir__component-row-table {
    cursor: default;
    padding: 5px;
  }
}
</style>
