export const MINIMAL = 0;
export const STUDY = 1;
export const SERIES = 2;
export const VIEW = 3;
export const BUNDLE_VIEW = 5;

export const BUNDLE = 'Bundle';

export const isMinimal = v => ((v - 1) % 3) + 1 === MINIMAL;
export const isStudy = v => ((v - 1) % 3) + 1 === STUDY;
export const isSeries = v => ((v - 1) % 3) + 1 === SERIES;
export const isView = v => ((v - 1) % 3) + 1 === VIEW;
export const isBundle = v => v === BUNDLE || ((v - 1) % 3) + 1 === BUNDLE_VIEW;
