<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div
      v-if="isValue(status, [3, 4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.DiagnosticReport.status')"
        :value="createShortText(status, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <template v-if="performerPractitioner">
      <div class="fhir-component__body__row">
        <b>{{ $t('resources.Practitioner') }}:</b>
        {{ performerPractitioner }}
      </div>
    </template>
    <div
      v-if="isValue(conclusion, [3], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.DiagnosticReport.conclusion')"
        :value="createShortText(conclusion, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :lang="lang" :resource="resource" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment, dateMoment } from '../utils/time.utils';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'DiagnosticReport',
  components: {
    FhirHeader,
    SingleValue,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    status() {
      return jp.value(this.fhir, 'resource.status') || '';
    },
    category() {
      return jp.value(this.fhir, 'resource.category') || '';
    },
    performerPractitioner() {
      const REFERENCE_NAME = 'Practitioner';
      const performers = jp.value(this.fhir, 'resource.performer');
      const containerContained = jp.value(this.fhir, 'resource.contained');
      if (
        performers &&
        performers.length !== 0 &&
        containerContained &&
        containerContained.length !== 0
      ) {
        const foundReferencePerformer = performers.find(
          item =>
            item.actor.reference.indexOf(REFERENCE_NAME) !== -1 ||
            item.actor.reference.indexOf(`#${REFERENCE_NAME}`) !== -1
        ).actor.reference;
        const foundReferenceContained = containerContained.find(
          item =>
            item.id ===
            (foundReferencePerformer.substring(0, 1) === '#'
              ? foundReferencePerformer.substring(1)
              : foundReferencePerformer)
        );
        if (
          foundReferencePerformer &&
          foundReferenceContained &&
          foundReferenceContained.name &&
          foundReferenceContained.name[0]
        ) {
          return `${
            foundReferenceContained.name[0].family
          } ${foundReferenceContained.name[0].given.join(' ')}`;
        }
      }
      return '';
    },
    effectiveDateTime() {
      const date = jp.value(this.fhir, 'resource.effectiveDateTime') || '';
      if (date) {
        return dateTimeMoment(date);
      } else {
        return null;
      }
    },
    issued() {
      const date = jp.value(this.fhir, 'resource.issued') || '';
      if (date) {
        return dateTimeMoment(date);
      } else {
        return null;
      }
    },
    onsetDateTime() {
      const getDateTime = jp.value(this.fhir, 'resource.onsetDateTime') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    assertedDate() {
      const getDateTime = jp.value(this.fhir, 'resource.assertedDate') || '';
      if (getDateTime) {
        return dateMoment(getDateTime);
      } else {
        return '';
      }
    },
    conclusion() {
      return jp.value(this.fhir, 'resource.conclusion') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
