<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <!-- <div v-if="status" class="fhir-component__body__row">
      <SingleValue
        ref="statusRef"
        :label="$t('resourcesStructures.Observation.status')"
        :value="status"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div> -->
    <div
      v-if="category && disableOnMode(2, mode)"
      class="fhir-component__body__row"
    >
      <template v-for="(item, index) in category" :key="index">
        <CodeableElement
          :label="$t('resourcesStructures.Observation.category')"
          :value="item"
          :short-text="shortText"
          :max-text-length="maxTextLength"
          :lang="lang"
        />
      </template>
    </div>
    <!-- <div
      v-if="subject && disableOnMode(2, mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="subjectRef"
        :label="$t('resourcesStructures.Observation.subject')"
        :value="subject.display || subject.reference || ''"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div> -->
    <div
      v-if="method && disableOnMode(2, mode)"
      class="fhir-component__body__row"
    >
      <CodeableElement
        ref="methodRef"
        :label="$t('resourcesStructures.Observation.method')"
        :value="method"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div>
    <div
      v-if="bodySite.length > 0 && disableOnMode(2, mode)"
      class="fhir-component__body__row"
    >
      <CodeableElement
        v-if="bodySite"
        ref="bodySiteRef"
        :label="$t('resourcesStructures.Observation.bodySite')"
        :value="bodySite"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div>
    <div
      v-if="referenceRange && disableOnMode(2, mode)"
      class="fhir-component__body__row"
    >
      <template v-for="(item, index) in referenceRange">
        <div v-if="checkIfHighReference(item)" :key="index">
          <SingleValue
            :label="$t('resourcesStructures.Observation.temperature')"
            :value="checkIfHighReference(item)"
            :short-text="shortText"
            :max-text-length="maxTextLength"
            :lang="lang"
          />
        </div>
        <div v-if="checkIfLowReference(item)" :key="index">
          <SingleValue
            :label="$t('resourcesStructures.Observation.temperature')"
            :value="checkIfLowReference(item)"
            :short-text="shortText"
            :max-text-length="maxTextLength"
            :lang="lang"
          />
        </div>
      </template>
    </div>
    <div
      v-if="values && disableOnMode(2, mode)"
      class="fhir-component__body__row"
    >
      <Value
        v-if="values"
        :json-obj="values"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :is-active-label="true"
        :lang="lang"
      />
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :lang="lang" :resource="resource" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment } from '../utils/time.utils';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import CodeableElement from '../CodeableElement/CodeableElement';
import disableOnMode from '../utils/disableOnMode';
import Value from '../Value/Value';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'ObservationOtherTypes',
  components: {
    FhirHeader,
    SingleValue,
    CodeableElement,
    Value,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    // status() {
    //   return jp.value(this.fhir, 'resource.status') || '';
    // },
    category() {
      return jp.value(this.fhir, 'resource.category') || '';
    },
    // subject() {
    //   return jp.value(this.fhir, 'resource.subject') || '';
    // },
    bodySite() {
      return jp.value(this.fhir, 'resource.bodySite') || '';
    },
    method() {
      return jp.value(this.fhir, 'resource.method') || '';
    },
    referenceRange() {
      return jp.value(this.fhir, 'resource.referenceRange') || '';
    },
    issued() {
      const time = jp.value(this.fhir, 'resource.issued');
      if (time) {
        return dateTimeMoment(time);
      } else {
        return '';
      }
    },
    values() {
      return jp.value(this.fhir, 'resource');
    }
  },
  methods: {
    disableOnMode,
    checkIfHighReference(value) {
      if (value && value.high && value.high.unit && value.high.value) {
        return `${value.high.value} ${value.high.unit}`;
      } else {
        return '';
      }
    },
    checkIfLowReference(value) {
      if (value && value.low && value.low.unit && value.low.value) {
        return `${value.low.value} ${value.low.unit}`;
      } else {
        return '';
      }
    }
  }
};
</script>

<style scoped></style>
