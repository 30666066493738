<template>
  <span class="fh-ml" v-if="inline">{{ period }}</span>
  <div v-else>
    <div
      v-for="(item, index) in periods"
      :key="index"
      class="fhir-component__body__row"
    >
      <span>
        <b>{{ item.label }}:</b>
        {{ item.value }}
      </span>
    </div>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import tools from '../utils/tools';
import { dateTimeMoment } from '../utils/time.utils';

export default {
  name: 'Period',
  mixins: [mixins],
  props: {
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    period() {
      let ret = '';
      ret = tools.append(ret, this.periodStart, '');
      ret = tools.append(ret, this.periodEnd, ' - ');
      return ret;
    },
    periodStart() {
      if (this.jsonObj) {
        if (this.jsonObj.start) {
          return dateTimeMoment(this.jsonObj.start);
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    periodEnd() {
      if (this.jsonObj) {
        if (this.jsonObj.end) {
          return dateTimeMoment(this.jsonObj.end);
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    periods() {
      if (this.periodStart && this.periodEnd) {
        return [
          {
            label: this.$t('labels.starts'),
            value: this.periodStart
          },
          {
            label: this.$t('labels.ends'),
            value: this.periodEnd
          }
        ];
      }
      return [];
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
