<template>
  <div class="fhir-component__series-mode-entry--set-flex">
    <div
      v-for="(seriesElement, seriesIndex) in series"
      :key="seriesIndex"
      class="fhir-component__series-mode-entry fhir-component__series-mode-entry--set-flex--element"
    >
      <div class="fhir-component__imgaging-study-wrap-img-link">
        <div class="fhir-component__img-wrapper">
          <div v-if="seriesElement.endpoint">
            <div
              v-for="(endpoint, key) in getImages(seriesElement.endpoint)"
              :key="key"
            >
              <img
                :id="`img-${seriesIndex}${key}`"
                :src="`data:image/png;base64,${endpoint.content}`"
                class="fhir-component__series-mode-image fhir__component-text-center"
                alt="fhir-component__imaging-study-image"
                @error="onImageError($event)"
              />
              <div
                :id="`placeholder-${seriesIndex}${key}`"
                class="fhir-component__d-none"
              >
                <template v-if="seriesElement.modality">
                  <b>{{ seriesElement.modality.code }}</b>
                </template>
              </div>
            </div>
          </div>
          <div
            v-else
            class="fhir-component__series-mode-image fhir__component-text-center"
          >
            <template v-if="seriesElement.modality">
              <b>{{ seriesElement.modality.code }}</b>
            </template>
          </div>
        </div>
      </div>
      <aside>
        <template v-if="seriesElement.modality">
          <SingleValue
            :label="$t('resourcesStructures.ImagingStudy.modality')"
            :value="seriesElement.modality.code"
            :lang="lang"
          />
        </template>
        <template v-if="seriesElement.description">
          <SingleValue
            :label="$t('labels.description')"
            :value="seriesElement.description"
            :lang="lang"
          />
        </template>
        <template v-if="seriesElement.bodySite && seriesElement.bodySite.code">
          <SingleValue
            :label="$t('labels.bodysite')"
            :value="seriesElement.bodySite.code"
            :lang="lang"
          />
        </template>
      </aside>
    </div>
  </div>
</template>
<script>
import SingleValue from '../SingleValue/SingleValue';
import mixins from '../utils/mixins';

export default {
  name: 'SeriesModeElement',
  components: {
    SingleValue
  },
  mixins: [mixins],
  props: {
    series: { type: [Array, String], required: true },
    contained: { type: [Array, String], required: false }
  },
  methods: {
    getImages(endpoint) {
      if (endpoint && this.contained) {
        const referencesEndpoint = endpoint.map(item =>
          item.reference.replace('#', '')
        );
        let res = this.contained.filter(item =>
          referencesEndpoint.some(ref => ref === item.id)
        );
        if (res.length === 0) {
          res = referencesEndpoint;
        }
        return res;
      } else {
        return [];
      }
    },
    onImageError(event) {
      event.target.classList.add('fhir-component__d-none');
      event.target.nextSibling.classList.add('fhir-component__d-flex');
    }
  }
};
</script>

<style scoped></style>
