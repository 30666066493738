<template>
  <Patient
    v-if="finalDisplay === 'Patient'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Patient>
  <Organization
    v-else-if="finalDisplay === 'Organization'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Organization>
  <Encounter
    v-else-if="finalDisplay === 'Encounter'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Encounter>
  <DiagnosticReport
    v-else-if="finalDisplay === 'DiagnosticReport'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </DiagnosticReport>
  <EpisodeOfCare
    v-else-if="finalDisplay === 'EpisodeOfCare'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </EpisodeOfCare>
  <MedicationRequest
    v-else-if="finalDisplay === 'MedicationRequest'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </MedicationRequest>
  <MedicationAdministration
    v-else-if="finalDisplay === 'MedicationAdministration'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </MedicationAdministration>
  <ImagingStudy
    v-else-if="finalDisplay === 'ImagingStudy'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </ImagingStudy>
  <Observation
    v-else-if="finalDisplay === 'Observation'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Observation>
  <DocumentReference
    v-else-if="finalDisplay === 'DocumentReference'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </DocumentReference>
  <Practitioner
    v-else-if="finalDisplay === 'Practitioner'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Practitioner>
  <Procedure
    v-else-if="finalDisplay === 'Procedure'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Procedure>
  <ProcedureRequest
    v-else-if="finalDisplay === 'ProcedureRequest'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </ProcedureRequest>
  <ServiceRequest
    v-else-if="finalDisplay === 'ServiceRequest'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </ServiceRequest>
  <Condition
    v-else-if="finalDisplay === 'Condition'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Condition>
  <Specimen
    v-else-if="finalDisplay === 'Specimen'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Specimen>
  <Consent
    v-else-if="finalDisplay === 'Consent'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Consent>
  <Medication
    v-else-if="finalDisplay === 'Medication'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </Medication>
  <AllergyIntolerance
    v-else-if="finalDisplay === 'AllergyIntolerance'"
    :i18n="i18n"
    :show-tags="showTags"
    :edit-tags="editTags"
    :mode="finalMode"
    :resource="{ resource: resource }"
    :lang="language"
    :token="finalToken"
  >
    <slot />
  </AllergyIntolerance>

  <!--  <DefaultFhirViewer v-else :mode="mode" :resource="resource">-->
  <!--    <slot />-->
  <!--  </DefaultFhirViewer>-->
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { getLang } from '@/components/Commons/AccessibilityMenu/AccessibilityActions';
import Patient from '@/components/FhirComponents/Patient/Patient.vue';
import i18nGlobal from '@/lang/index';
import Organization from '@/components/FhirComponents/Organization/Organization.vue';
import Encounter from '@/components/FhirComponents/Encounter/Encounter.vue';
import DiagnosticReport from '@/components/FhirComponents/DiagnosticReport/DiagnosticReport.vue';
import EpisodeOfCare from '@/components/FhirComponents/EpisodeOfCare/EpisodeOfCare.vue';
import MedicationRequest from '@/components/FhirComponents/MedicationRequest/MedicationRequest.vue';
import MedicationAdministration from '@/components/FhirComponents/MedicationAdministration/MedicationAdministration.vue';
import ImagingStudy from '@/components/FhirComponents/ImagingStudy/ImagingStudy.vue';
import Observation from '@/components/FhirComponents/Observation/Observation.vue';
import DocumentReference from '@/components/FhirComponents/DocumentReference/DocumentReference.vue';
import Practitioner from '@/components/FhirComponents/Practitioner/Practitioner.vue';
import Procedure from '@/components/FhirComponents/Procedure/Procedure.vue';
import ProcedureRequest from '@/components/FhirComponents/ProcedureRequest/ProcedureRequest.vue';
import ServiceRequest from '@/components/FhirComponents/ServiceRequest/ServiceRequest.vue';
import Condition from '@/components/FhirComponents/Condition/Condition.vue';
import Specimen from '@/components/FhirComponents/Specimen/Specimen.vue';
import Consent from '@/components/FhirComponents/Consent/Consent.vue';
import Medication from '@/components/FhirComponents/Medication/Medication.vue';
import AllergyIntolerance from '@/components/FhirComponents/AllergyIntolerance/AllergyIntolerance.vue';
import useUser from '@/composables/useUser';

export default defineComponent({
  name: 'FhirComponentsRouter',
  emits: ['get-active-header'],
  components: {
    AllergyIntolerance,
    Medication,
    Consent,
    Specimen,
    Condition,
    ServiceRequest,
    ProcedureRequest,
    Procedure,
    Practitioner,
    DocumentReference,
    Observation,
    ImagingStudy,
    MedicationAdministration,
    MedicationRequest,
    EpisodeOfCare,
    DiagnosticReport,
    Encounter,
    Organization,
    Patient
    //FhirFormsRouter: () => import('../forms/FhirFormsRouter'),
  },
  props: {
    resource: {
      type: Object as PropType<any>,
      required: false
    },
    resources: {
      type: Object as PropType<any[]>,
      required: false
    },
    lang: {
      type: String,
      required: false
    },
    mode: {
      type: Number,
      required: false,
      default: 1
    },
    token: {
      type: String,
      required: false
    },
    hideForm: {
      type: Boolean,
      required: false,
      default: false
    },
    showTags: {
      type: Boolean,
      required: false,
      default: true
    },
    editTags: {
      type: Boolean,
      required: false,
      default: false
    },
    forceViewer: { default: false, type: Boolean, required: false }
  },
  setup(props) {
    const finalToken = computed(() => props.token || useUser().getToken());
    const finalDisplay = computed(() => {
      return (props.resource as any)?.resourceType;
    });
    const language = computed(() => props.lang || getLang() || 'pl');
    const finalMode = computed(() => props.mode);

    const i18n = computed(() => i18nGlobal);

    return {
      finalToken,
      finalDisplay,
      language,
      finalMode,
      i18n
    };
  }
});
</script>

<style scoped></style>
