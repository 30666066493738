<template>
  <div class="fhir-component__card ex-viewer">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
    >
      <slot />
    </FhirHeader>
    <div class="ex-buttons" :class="{ 'ex-buttons-alpha': showIframe }">
      <a
        class="fhir-component__source-link fhir-component__source-link--imaging-study ex-button"
        target="_blank"
        @click="showIframe = true"
      >
        {{ $t('link.preview') }} ExhibeonWeb
      </a>
      <button
        class="fhir-component__source-link fhir-component__source-link--imaging-study ex-button"
        @click="handleOpenExhibeon3ViewerMode"
      >
        {{ $t('link.preview') }} Exhibeon3
      </button>
    </div>
    <div v-if="showIframe" class="fhir-component__wrapper__iframe">
      <iframe
        id="iframe"
        class="fhir-component__iframe"
        :src="sourceToOutsideResource"
      />
    </div>
  </div>
</template>

<script>
import jp from 'jsonpath';
import FhirHeader from '../Appearance/FhirHeader';
import mixins from '../utils/mixins';
import outsideResourceAndExhibeon3 from './mixins/outsideResourceAndExhibeon3';

export default {
  name: 'ViewerMode',
  components: {
    FhirHeader
  },
  mixins: [mixins, outsideResourceAndExhibeon3],
  data() {
    return {
      showIframe: false
    };
  },
  computed: {
    tenant() {
      return jp.value(
        this.fhir,
        "resource.meta.tag[?(@.system=='urn:tenant')].code"
      );
    },
    typeFhir() {
      return jp.value(this.fhir, 'resource.resourceType') || '';
    },
    id() {
      return jp.value(this.fhir, 'resource.id') || '';
    }
  },
  watch: {
    forceViewer() {
      this.initViewer();
    },
    fhir() {
      this.initViewer();
    }
  },
  mounted() {
    this.initViewer();
  },
  methods: {
    handleOpenExhibeon3ViewerMode() {
      this.showIframe = false;
      this.handleOpenExhibeon3();
    },
    initViewer() {
      this.showIframe = this.forceViewer;
    }
  }
};
</script>

<style scoped>
.ex-buttons {
  display: inline-flex;
  position: absolute;
}
.ex-buttons-alpha {
  opacity: 0.3;
}

.ex-buttons:hover {
  opacity: 1;
}

.ex-buttons > * {
  margin-left: 5px;
  white-space: nowrap;
}
.ex-viewer {
  min-height: 70px;
}
.ex-button {
  background-color: white;
}
</style>
