<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div
      v-if="isValue(status, [4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.ProcedureRequest.status')"
        :value="createShortText(status, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div
      v-if="isValue(intent, [4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.ProcedureRequest.intent')"
        :value="createShortText(intent, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="category && disableOnMode([4, 10], mode)">
      <div
        v-for="(item, index) in category"
        :key="index"
        class="fhir-component__body__row"
      >
        <div v-if="item.coding">
          <CodeableElement
            :label="$t('resourcesStructures.ProcedureRequest.category')"
            :value="createShortText(item, shortText, maxTextLength)"
            :lang="lang"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isValue(priority, [4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.ProcedureRequest.priority')"
        :value="createShortText(priority, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div
      v-if="isValue(context.display, 4, mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.ProcedureRequest.context')"
        :value="createShortText(context.display, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="note" class="fhir-component__body__row">
      <div class="fhir-component__list-label">
        <b>{{ $t('text.note') }}</b>
      </div>
      <ul
        v-for="(item, index) in note"
        :key="`index-${index}-note-proced-requ`"
        class="fhir-component__list-element"
      >
        <li class="fhir-component__list-element__list-item">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment } from '../utils/time.utils';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import CodeableElement from '../CodeableElement/CodeableElement';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'ProcedureRequest',
  components: {
    FhirHeader,
    SingleValue,
    CodeableElement,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    status() {
      return jp.value(this.fhir, 'resource.status') || '';
    },
    intent() {
      return jp.value(this.fhir, 'resource.intent') || '';
    },
    priority() {
      return jp.value(this.fhir, 'resource.priority') || '';
    },
    category() {
      return jp.value(this.fhir, 'resource.category') || '';
    },
    context() {
      return jp.value(this.fhir, 'resource.context') || '';
    },
    note() {
      const getNote = jp.value(this.fhir, 'resource.note');
      if (getNote && getNote.length !== 0) {
        const list = [...getNote].map(item => item && item.text);
        return list;
      }
      return null;
    },
    authoredOn() {
      const getDateTime = jp.value(this.fhir, 'resource.authoredOn') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
