<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <span class="visuallyhidden">
      {{ $t('wcagText.allergyIntoleranceTitle') }}
    </span>
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div
      v-if="clinicalStatus && disableOnMode([2, 3, 4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="clinicalStatusRef"
        :label="$t('resourcesStructures.AllergyIntolerance.clinicalStatus')"
        :lang="lang"
        :value="createShortText(clinicalStatus, shortText, maxTextLength)"
      />
    </div>
    <div
      v-if="verificationStatus && disableOnMode([2, 3, 4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="verificationStatusRef"
        :label="$t('resourcesStructures.AllergyIntolerance.verificationStatus')"
        :lang="lang"
        :value="createShortText(verificationStatus, shortText, maxTextLength)"
      />
    </div>
    <div
      v-if="type && disableOnMode([2, 3, 4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="typeRef"
        :label="$t('resourcesStructures.AllergyIntolerance.type')"
        :lang="lang"
        :value="createShortText(type, shortText, maxTextLength)"
      />
    </div>
    <div
      v-if="category && disableOnMode([2, 3, 4, 10], mode)"
      class="fhir-component__body__row"
    >
      <span>
        <b>{{ $t('resourcesStructures.AllergyIntolerance.category') }}:</b>
      </span>
      <ul
        v-for="(item, index) in category"
        :key="index"
        class="fhir-component__list-element"
      >
        <li class="fhir-component__list-element__list-item">
          {{ createShortText(item, shortText, maxTextLength) }}
        </li>
      </ul>
    </div>
    <div
      v-if="criticality && disableOnMode([2, 3, 4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="criticalityRef"
        :label="$t('resourcesStructures.AllergyIntolerance.criticality')"
        :lang="lang"
        :value="createShortText(criticality, shortText, maxTextLength)"
      />
    </div>
    <div
      v-if="note && disableOnMode(4, mode)"
      class="fhir-component__body__row"
    >
      <ListElement
        ref="emitNoteRef"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :label="$t('resourcesStructures.AllergyIntolerance.note')"
        :lang="lang"
        :value="note"
      />
    </div>
    <div
      v-if="reaction && disableOnMode(3, mode)"
      class="fhir-component__body__row"
    >
      <p class="fhir-component__body__row">
        <b>{{ $t('resourcesStructures.AllergyIntolerance.reactions') }}:</b>
      </p>
      <div
        v-for="(data, indexReaction) in reaction"
        :key="indexReaction"
        class="fhir-component__body__row--move-10"
      >
        <p class="fhir-component__body__row">
          <b>
            {{
              `${$t(
                'resourcesStructures.AllergyIntolerance.reaction'
              )}.${indexReaction + 1}`
            }}
          </b>
        </p>
        <div>
          <div
            v-if="data.substance"
            class="fhir-component__body__row fhir-component__body__row--move-10"
          >
            <p class="fhir-component__body__row">
              <b>
                {{
                  $t(
                    'resourcesStructures.AllergyIntolerance.reaction-substance'
                  )
                }}:
              </b>
            </p>
            <CodeableConcept
              v-if="data.substance"
              :json-obj="data.substance"
              display-and-coding="true"
              :short-text="shortText"
              :max-text-length="maxTextLength"
              :lang="lang"
              class="fhir-component__body__row--move-10"
            />
          </div>
          <div
            v-if="data.manifestation"
            class="fhir-component__body__row fhir-component__body__row--move-10"
          >
            <p class="fhir-component__body__row">
              <b>
                {{
                  $t(
                    'resourcesStructures.AllergyIntolerance.reaction-manifestation'
                  )
                }}:
              </b>
            </p>
            <div v-for="(item, index) in data.manifestation" :key="index">
              <CodeableConcept
                v-if="item.coding"
                :json-obj="item.coding"
                display-and-coding="true"
                :short-text="shortText"
                :max-text-length="maxTextLength"
                :lang="lang"
                class="fhir-component__body__row--move-10"
              />
            </div>
          </div>
          <div v-if="data.description" class="fhir-component__body__row">
            <SingleValue
              :label="
                $t(
                  'resourcesStructures.AllergyIntolerance.reaction-description'
                )
              "
              :value="
                createShortText(data.description, shortText, maxTextLength)
              "
              :lang="lang"
            />
          </div>
          <div v-if="data.severity" class="fhir-component__body__row">
            <SingleValue
              :label="
                $t('resourcesStructures.AllergyIntolerance.reaction-severity')
              "
              :value="createShortText(data.severity, shortText, maxTextLength)"
              :lang="lang"
            />
          </div>
          <div v-if="data.exposureRoute" class="fhir-component__body__row">
            <p class="fhir-component__body__row">
              <b>
                {{
                  $t(
                    'resourcesStructures.AllergyIntolerance.reaction-exposureRoute'
                  )
                }}:
              </b>
            </p>
            <CodeableConcept
              v-if="data.exposureRoute"
              :json-obj="data.exposureRoute"
              display-and-coding="true"
              :short-text="shortText"
              :max-text-length="maxTextLength"
              :lang="lang"
              class="fhir-component__body__row--move-10"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment, dateMoment } from '../utils/time.utils';
import mixins from '../utils/mixins';
import CodeableConcept from '../CodeableConcept/CodeableConcept';
import SingleValue from '../SingleValue/SingleValue';
import ListElement from '../ListElement/ListElement';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'AllergyIntolerance',
  components: {
    FhirHeader,
    CodeableConcept,
    SingleValue,
    ListElement,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    clinicalStatus() {
      return jp.value(this.fhir, 'resource.clinicalStatus') || '';
    },
    verificationStatus() {
      return jp.value(this.fhir, 'resource.verificationStatus') || '';
    },
    type() {
      return jp.value(this.fhir, 'resource.type') || '';
    },
    category() {
      return jp.value(this.fhir, 'resource.category') || '';
    },
    criticality() {
      return jp.value(this.fhir, 'resource.criticality') || '';
    },
    note() {
      return jp.value(this.fhir, 'resource.note') || '';
    },
    onsetDateTime() {
      const getDateTime = jp.value(this.fhir, 'resource.onsetDateTime') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    assertedDate() {
      const getDateTime = jp.value(this.fhir, 'resource.assertedDate') || '';
      if (getDateTime) {
        return dateMoment(getDateTime);
      } else {
        return '';
      }
    },
    reaction() {
      return jp.value(this.fhir, 'resource.reaction') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
