<template>
  <div class="fhir-component__info-box">
    {{ infoFooter }}
  </div>
</template>

<script>
export default {
  name: 'InfoFooter',
  props: {
    infoFooter: { type: String, default: '', required: false }
  }
};
</script>

<style scoped></style>
