<template>
  <div>
    <template v-if="diagnosticReportDataInImagingStudy.performer">
      <div class="fhir-component__body__row">
        <b>{{ $t('resources.Practitioner') }}:</b>
        {{ diagnosticReportDataInImagingStudy.performer }}
      </div>
    </template>
    <div
      v-if="diagnosticReportDataInImagingStudy.conclusion"
      class="fhir-component__body__row fhir-component__conclusion"
    >
      <SingleValue
        :label="$t('resourcesStructures.DiagnosticReport.conclusion')"
        :value="diagnosticReportDataInImagingStudy.conclusion"
        :lang="lang"
      />
    </div>
    <p v-else class="fhir-component__no-description">
      {{ $t('labels.noDescription') }}
    </p>
    <button
      v-if="imagingStudyData && diagnosticReportDataInImagingStudy.conclusion"
      class="fhir-component__button-info"
      :title="$t('labels.showAdditionalInfo')"
      :lang="lang"
      @click="showInfo = !showInfo"
    >
      i
    </button>
    <ImagingStudyModeData
      v-if="showInfo"
      class="fhir-component__margin-top-10 fhir-component__margin-bottom-10"
      :imaging-study-data="imagingStudyData"
      :lang="lang"
    />
  </div>
</template>

<script>
import SingleValue from '../SingleValue/SingleValue';
import ImagingStudyModeData from './ImagingStudyModeData';
import mixins from '../utils/mixins';

export default {
  components: { ImagingStudyModeData, SingleValue },
  name: 'DiagnosticReportDataInImagingStudy',
  mixins: [mixins],
  props: {
    diagnosticReportDataInImagingStudy: {
      required: false,
      type: Object
    },
    imagingStudyData: { required: false, type: Object }
  },
  data() {
    return {
      showInfo: false
    };
  },
  mounted() {
    if (!this.diagnosticReportDataInImagingStudy.conclusion) {
      this.showInfo = true;
    }
  }
};
</script>

<style scoped></style>
