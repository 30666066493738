<template>
  <span>
    <ValueQuantity
      v-if="jsonObj.valueQuantity && jsonObj.valueQuantity.value.toString()"
      :json-obj="jsonObj"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :active-unit="activeUnit"
      :max-two-digits-after-dot="maxTwoDigitsAfterDot"
    />
    <ValueCodeableConcept
      v-else-if="jsonObj.valueCodeableConcept"
      :json-obj="jsonObj"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
    <span
      v-else-if="jsonObj.valueString"
      :jsonObj="jsonObj"
      :shortText="shortText"
      :maxTextLength="maxTextLength"
      class="plain-text"
    >
      {{ jsonObj.valueString }}
    </span>
    <span
      v-else-if="jsonObj.valueBoolean"
      :jsonObj="jsonObj"
      :shortText="shortText"
      :maxTextLength="maxTextLength"
    >
      {{ jsonObj.valueBoolean }}
    </span>
    <ValueRange
      v-else-if="jsonObj.valueRange"
      :json-obj="jsonObj"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
    <ValueRatio
      v-else-if="jsonObj.valueRatio"
      :json-obj="jsonObj"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
    <ValueSampledData
      v-else-if="jsonObj.valueSampledData"
      :json-obj="jsonObj"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
    <ValueAttachment
      v-else-if="jsonObj.valueAttachment"
      :json-obj="jsonObj"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
    <span
      v-else-if="jsonObj.valueTime"
      :jsonObj="jsonObj"
      :shortText="shortText"
      :maxTextLength="maxTextLength"
    >
      {{ jsonObj.valueTime }}
    </span>
    <span
      v-else-if="jsonObj.valueDateTime"
      :jsonObj="jsonObj"
      :shortText="shortText"
      :maxTextLength="maxTextLength"
    >
      {{ jsonObj.valueDateTime }}
    </span>
    <ValuePeriod
      v-else-if="jsonObj.valuePeriod"
      :json-obj="jsonObj"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
  </span>
</template>

<script>
import mixins from '../utils/mixins';
import ValueQuantity from './ValueQuantity';
import ValueCodeableConcept from './ValueCodeableConcept';
import ValueRange from './ValueRange';
import ValueRatio from './ValueRatio';
import ValueSampledData from './ValueSampledData';
import ValuePeriod from './ValuePeriod';
import ValueAttachment from './ValueAttachment';

export default {
  name: 'Value',
  components: {
    ValueSampledData,
    ValueRatio,
    ValueRange,
    ValueQuantity,
    ValueCodeableConcept,
    ValuePeriod,
    ValueAttachment
  },
  mixins: [mixins],
  props: {
    isActiveLabel: { type: Boolean, required: false, default: false },
    activeUnit: { type: Boolean, required: false, default: true },
    maxTwoDigitsAfterDot: { type: Boolean, required: false, default: false }
  },
  computed: {
    valueString() {
      if (this.jsonObj) {
        return this.jsonObj.valueString;
      } else {
        return '';
      }
    },
    valueBoolean() {
      if (this.jsonObj) {
        return this.jsonObj.valueBoolean;
      } else {
        return '';
      }
    },
    valueTime() {
      if (this.jsonObj) {
        return this.jsonObj.valueTime;
      } else {
        return '';
      }
    },
    valueDateTime() {
      if (this.jsonObj) {
        return this.jsonObj.valueDateTime;
      } else {
        return '';
      }
    }
  }
};
</script>

<style scoped>
.plain-text {
  white-space: pre-line;
}
</style>
