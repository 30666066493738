<template>
  <div ref="card" :class="[!noBorders ? 'fhir-component__card' : '']">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
    >
      <slot />
    </FhirHeader>
    <DiagnosticReportDataInImagingStudy
      v-if="diagnosticReportDataInImagingStudy"
      :diagnostic-report-data-in-imaging-study="
        diagnosticReportDataInImagingStudy
      "
      :lang="lang"
    />
    <div class="fhir-component__body">
      <template v-if="isBundle(type)">
        <div v-for="(item, index) in entry" :key="index">
          <SeriesModeElement
            :series="item.resource.series"
            :contained="item.resource.contained"
            :lang="lang"
          />
        </div>
      </template>
      <template v-else>
        <SeriesModeElement
          :series="series"
          :contained="cotnained"
          :lang="lang"
        />
      </template>
    </div>
  </div>
</template>

<script>
import jp from 'jsonpath';
import SeriesModeElement from './SeriesModeElement';
import { isBundle } from './modes';
import mixins from '../utils/mixins';
import FhirHeader from '../Appearance/FhirHeader';
import DiagnosticReportDataInImagingStudy from './DiagnosticReportDataInImagingStudy';

export default {
  name: 'SeriesMode',
  components: {
    DiagnosticReportDataInImagingStudy,
    FhirHeader,
    SeriesModeElement
  },
  mixins: [mixins],
  props: {
    noBorders: { default: false, type: Boolean, required: false },
    diagnosticReportDataInImagingStudy: {
      required: false,
      type: Object
    }
  },
  computed: {
    entry() {
      return jp.value(this.fhir, 'resource.entry') || '';
    },
    type() {
      return jp.value(this.fhir, 'resource.resourceType') || '';
    },
    series() {
      return jp.value(this.fhir, 'resource.series') || '';
    },
    cotnained() {
      return jp.value(this.fhir, 'resource.contained');
    }
  },
  methods: {
    isBundle
  }
};
</script>

<style></style>
