<template>
  <span>
    <Quantity
      v-if="jsonObj.valueQuantity"
      :json-obj="jsonObj.valueQuantity"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :active-unit="activeUnit"
      :max-two-digits-after-dot="maxTwoDigitsAfterDot"
    />
  </span>
</template>

<script>
import mixins from '../utils/mixins';
import Quantity from '../Quantity/Quantity';

export default {
  name: 'ValueQuantity',
  components: { Quantity },
  mixins: [mixins],
  props: {
    activeUnit: { type: Boolean, required: false, default: true },
    maxTwoDigitsAfterDot: { type: Boolean, required: false, default: false }
  }
};
</script>

<style scoped></style>
