<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div v-if="isValue(patient, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('resourcesStructures.Consent.consentPatient')"
        :value="createShortText(patient.display, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="isValue(status, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('labels.status')"
        :value="createShortText(status, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="isValue(attachment, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('labels.attachment')"
        :value="createShortText(attachment, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment } from '../utils/time.utils';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'Consent',
  components: {
    FhirHeader,
    SingleValue,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    patient() {
      return jp.value(this.fhir, 'resource.patient') || '';
    },
    status() {
      return jp.value(this.fhir, 'resource.status') || '';
    },
    dateTime() {
      const date = jp.value(this.fhir, 'resource.dateTime');
      if (date) {
        return dateTimeMoment(date);
      } else {
        return '';
      }
    },
    attachment() {
      return jp.value(this.fhir, 'resource.sourceAttachment.title') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
