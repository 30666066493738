<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <template v-if="performerPractitioner">
      <div class="fhir-component__body__row">
        <b>{{ $t('resources.Practitioner') }}:</b>
        {{ performerPractitioner }}
      </div>
    </template>
    <div v-if="values" class="fhir-component__body__row">
      <Value
        v-if="values"
        :json-obj="values"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :is-active-label="true"
        :lang="lang"
      />
    </div>
    <template v-if="infoFooterText && observationCode === 'edss-scale'">
      <InfoFooter :info-footer="infoFooterText" />
    </template>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import jp from 'jsonpath';
import disableOnMode from '../utils/disableOnMode';
import Value from '../Value/Value';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';
import InfoFooter from '../Info/InfoFooter';

export default {
  name: 'ObservationTherapy',
  components: {
    InfoFooter,
    FhirHeader,
    Value,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    subject() {
      return jp.value(this.fhir, 'resource.subject') || '';
    },
    performerPractitioner() {
      const REFERENCE_NAME = 'Practitioner';
      const performers = jp.value(this.fhir, 'resource.performer');
      const containerContained = jp.value(this.fhir, 'resource.contained');
      if (
        performers &&
        performers.length !== 0 &&
        containerContained &&
        containerContained.length !== 0
      ) {
        const foundReferencePerformer = performers.find(
          item =>
            item.reference.indexOf(REFERENCE_NAME) !== -1 ||
            item.reference.indexOf(`#${REFERENCE_NAME}`) !== -1
        ).reference;
        const foundReferenceContained = containerContained.find(
          item =>
            item.id ===
            (foundReferencePerformer.substring(0, 1) === '#'
              ? foundReferencePerformer.substring(1)
              : foundReferencePerformer)
        );
        if (
          foundReferencePerformer &&
          foundReferenceContained &&
          foundReferenceContained.name &&
          foundReferenceContained.name[0]
        ) {
          return `${
            foundReferenceContained.name[0].family
          } ${foundReferenceContained.name[0].given.join(' ')}`;
        }
      }
      return '';
    },
    observationCode() {
      const categories = jp.value(
        this.fhir || {},
        'resource.code.coding[*].code'
      );
      return categories ? categories.trim() : null;
    },
    values() {
      return jp.value(this.fhir, 'resource');
    },
    infoHeaderText() {
      if (this.infoHeader) {
        return this.infoHeader;
      }
      return this.$t('text.infoDiagnosticGeneral');
    },
    infoFooterText() {
      if (this.infoFooter) {
        return this.infoFooter;
      }
      return this.$t('text.infoDiagnosticGeneral');
    }
  },
  methods: {
    disableOnMode
  }
};
</script>

<style scoped></style>
