<template>
  <div>
    <span v-if="attachmentContentType">
      {{ createShortText(attachmentContentType, shortText, maxTextLength) }}
    </span>
    <span v-if="attachmentLanguage">
      {{ createShortText(attachmentLanguage, shortText, maxTextLength) }}
    </span>
    <span v-if="attachmentData">
      {{ createShortText(attachmentData, shortText, maxTextLength) }}
    </span>
    <span v-if="attachmentUrl">
      {{ createShortText(attachmentUrl, shortText, maxTextLength) }}
    </span>
    <span v-if="attachmentSize">
      {{ createShortText(attachmentSize, shortText, maxTextLength) }}
    </span>
    <span v-if="attachmentHash">
      {{ createShortText(attachmentHash, shortText, maxTextLength) }}
    </span>
    <span v-if="attachmentTitle">
      {{ createShortText(attachmentTitle, shortText, maxTextLength) }}
    </span>
    <span v-if="attachmentCreation">
      {{ createShortText(attachmentCreation, shortText, maxTextLength) }}
    </span>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import createShortText from '../utils/createShortText';

export default {
  name: 'Attachment',
  mixins: [mixins],
  computed: {
    attachmentContentType() {
      if (this.jsonObj) {
        return this.jsonObj.contentType;
      } else {
        return '';
      }
    },
    attachmentLanguage() {
      if (this.jsonObj) {
        return this.jsonObj.language;
      } else {
        return '';
      }
    },
    attachmentData() {
      if (this.jsonObj) {
        return this.jsonObj.data;
      } else {
        return '';
      }
    },
    attachmentUrl() {
      if (this.jsonObj) {
        return this.jsonObj.url;
      } else {
        return '';
      }
    },
    attachmentSize() {
      if (this.jsonObj) {
        return this.jsonObj.size;
      } else {
        return '';
      }
    },
    attachmentHash() {
      if (this.jsonObj) {
        return this.jsonObj.hash;
      } else {
        return '';
      }
    },
    attachmentTitle() {
      if (this.jsonObj) {
        return this.jsonObj.title;
      } else {
        return '';
      }
    },
    attachmentCreation() {
      if (this.jsonObj) {
        return this.jsonObj.creation;
      } else {
        return '';
      }
    }
  },
  methods: {
    createShortText
  }
};
</script>

<style scoped></style>
