<template>
  <div>
    <span v-if="rangeHigh && rangeLow">
      <span v-if="rangeHigh">
        {{ createShortText(rangeHigh, shortText, maxTextLength) }}
      </span>
      <span v-if="rangeLow">
        {{ createShortText(rangeLow, shortText, maxTextLength) }}
      </span>
    </span>
    <span v-else-if="rangeHigh && !rangeLow">
      <span v-if="rangeHigh">
        <span v-if="rangeHigh.value">
          {{ createShortText(rangeHigh.value, shortText, maxTextLength) }}
        </span>
      </span>
    </span>
    <span v-else-if="!rangeHigh && rangeLow">
      <span v-if="rangeLow">
        <span v-if="rangeLow.value">
          {{ createShortText(rangeLow.value, shortText, maxTextLength) }}
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import createShortText from '../utils/createShortText';

export default {
  name: 'Range',
  mixins: [mixins],
  computed: {
    rangeHigh() {
      if (this.jsonObj) {
        return this.jsonObj.high;
      } else {
        return '';
      }
    },
    rangeLow() {
      if (this.jsonObj) {
        return this.jsonObj.low;
      } else {
        return '';
      }
    }
  },
  methods: {
    createShortText
  }
};
</script>
