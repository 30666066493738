<template>
  <div>
    <Period
      v-if="jsonObj.valuePeriod"
      :json-obj="jsonObj.valuePeriod"
      :short-text="shortText"
      :max-text-length="maxTextLength"
    />
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import Period from '../Period/Period';

export default {
  name: 'ValuePeriod',
  components: { Period },
  mixins: [mixins]
};
</script>

<style scoped></style>
