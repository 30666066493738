<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <VolumeBrainTableDivision
      class="fhir-component__separate-bottom"
      :display-types="displayTypes(getAllPairs)"
      :get-all-pairs="getAllPairs"
      :selected-item="selectedItem"
      :lang="lang"
      @get-selected-item="setSelectedItem"
    />
    <VolumeBrainTableGeneral
      :display-types="displayTypes(getAllWithoutPairs)"
      :get-all-without-pairs="getAllWithoutPairs"
      :selected-item="selectedItem"
      :lang="lang"
      @get-selected-item="setSelectedItem"
    />
    <template v-if="infoFooterText">
      <InfoFooter :info-footer="infoFooterText" />
    </template>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import createShortText from '../utils/createShortText';
import InfoFooter from '../Info/InfoFooter';
import VolumeBrainTableDivision from './VolumeBrainTableDivision';
import VolumeBrainTableGeneral from './VolumeBrainTableGeneral';
import { importantDataCodesBrain } from '../utils/brainAnalysis.utils';
import { createPairs } from '../utils/brainAnalysis.utils';
import mixins from '../utils/mixins';
import disableOnMode from '../utils/disableOnMode';
import FhirHeader from '../Appearance/FhirHeader';
import MiniMode from '../MiniMode/MiniMode';

export default {
  name: 'ObservationVolumeBrain',
  mixins: [mixins],
  components: {
    VolumeBrainTableGeneral,
    VolumeBrainTableDivision,
    InfoFooter,
    FhirHeader,
    MiniMode
  },
  props: {
    showImportantData: { default: false, type: Boolean, required: false }
  },
  data() {
    return {
      importantDataCodesBrain: importantDataCodesBrain,
      selectedItem: null
    };
  },
  methods: {
    disableOnMode,
    createShortText,
    displayTypes(lists) {
      if (this.showImportantData) {
        return lists
          .filter(item =>
            item.coding.find(
              it =>
                it.code ===
                this.importantDataCodesBrain.find(
                  dataCode =>
                    dataCode.trim().toLowerCase() ===
                    it.code.trim().toLowerCase()
                )
            )
          )
          .map(item => ({
            name: item.name,
            code: item.coding[0].code || null
          }));
      }
      return lists.map(item => ({
        name: item.name,
        code: item.coding[0].code || null
      }));
    },
    setSelectedItem(item) {
      this.selectedItem = item;
    }
  },
  computed: {
    jsonObject() {
      return jp.value(this.fhir, 'resource') || '';
    },
    component() {
      return jp.value(this.fhir, 'resource.component') || '';
    },
    infoHeaderText() {
      if (this.infoHeader) {
        return this.infoHeader;
      }
      return this.$t('labelsRes.infoDataDiagnostic');
    },
    infoFooterText() {
      if (this.infoFooter) {
        return this.infoFooter;
      }
      return this.$t('labelsRes.infoDataDiagnostic');
    },
    getAllPairs() {
      return createPairs(this.jsonObject).filter(
        item => item.left.value && item.right.value
      );
    },
    getAllWithoutPairs() {
      return createPairs(this.jsonObject).filter(
        item => !item.left.value && !item.right.value && item.value
      );
    }
  }
};
</script>

<style scoped></style>
