<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>

    <ul
      v-if="showPatientInfo && disableOnMode(5, mode)"
      class=" fhir-component__form__form-ul"
    >
      <li
        v-for="(itemInfoPatient, indexInfoPatient) in infoPatient"
        :key="'!@' + indexInfoPatient"
        class="fhir-component__body__row fhir-component__d-flex-wrap"
      >
        <b>
          <template v-if="itemInfoPatient.code && itemInfoPatient.code.text">
            {{ itemInfoPatient.code.text }}
          </template>
          <CodeableConcept
            v-else-if="itemInfoPatient.code"
            :json-obj="itemInfoPatient.code"
            :short-text="shortText"
            :max-text-length="maxTextLength"
            :lang="lang"
          />
        </b>
        :
        <Value
          v-if="itemInfoPatient"
          :json-obj="itemInfoPatient"
          :short-text="shortText"
          :max-text-length="maxTextLength"
          :lang="lang"
        />
      </li>
      <li v-if="comment" class="fhir-component__body__row">
        <b>{{ $t('labels.comment') }}</b>
        : {{ comment }}
      </li>
    </ul>

    <table class="fhir__component-table-w-100 fhir__component-table">
      <tr>
        <th class="fhir__component-text-center fhir__component-th-table" />
        <th
          v-for="(item, index) in setTopColumn"
          :key="`${item}-${index}`"
          class="fhir__component-text-center fhir__component-th-table"
        >
          {{ item }}
        </th>
      </tr>
      <tr
        v-for="(itemType, indexType) in setLeftColumn"
        :key="`${itemType}-${indexType}`"
        :class="[
          'fhir__component-row-table fhir-component__row-pointer',
          indexType % 2 !== 0 ? 'fhir__component-background' : ''
        ]"
      >
        <td class="fhir__component-row-table">
          {{ itemType }}
        </td>
        <td
          v-for="(itemValue, indexValue) in setTopColumn"
          :key="`${itemValue}-${indexValue}`"
          class="fhir__component-row-table"
        >
          <Value
            v-if="
              getDataFromTableIndex(indexType, indexValue) &&
                getDataFromTableIndex(indexType, indexValue).object
            "
            :json-obj="getDataFromTableIndex(indexType, indexValue).object"
            :short-text="shortText"
            :max-text-length="maxTextLength"
            :active-unit="activeUnit"
            :lang="lang"
          />
          <span v-else>
            ---
          </span>
        </td>
      </tr>
    </table>

    <div
      v-if="getAttachments && disableOnMode(3, mode)"
      class="fhir-component__spiro-mask-image-wrapper"
    >
      <div
        v-for="(itemAttachment, indexAttachment) in getAttachments"
        :key="'+$' + indexAttachment"
        class="fhir-component__spiro-img-wrapper"
      >
        <img
          v-if="itemAttachment.data"
          class="fhir-component__spiro-img"
          :src="`data:image/png;base64,${itemAttachment.data}`"
          alt="img"
          @error="$event => ($event.target.style.display = 'none')"
        />
      </div>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :lang="lang" :resource="resource" />
  </div>
</template>

<script>
import createShortText from '../utils/createShortText';
import mixins from '../utils/mixins';
import jp from 'jsonpath';
import disableOnMode from '../utils/disableOnMode';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';
import CodeableConcept from '../CodeableConcept/CodeableConcept';
import Value from '../Value/Value';

export default {
  name: 'ObservationSpirometry',
  mixins: [mixins],
  components: {
    FhirHeader,
    MiniMode,
    CodeableConcept,
    Value
  },
  props: {
    showPatientInfo: { default: true, type: Boolean },
    filterMmts: { default: () => [], type: Array },
    activeUnit: { default: false, type: Boolean }
  },
  data() {
    return {
      listLeftColumn: [],
      listRightColumn: [],
      disabledDisplayValues: [
        'default',
        'Body height Measured',
        'Body weight Measured',
        'Tobacco smoking status',
        'Additional information about patient',
        'restriction is unlikely',
        'variant of the standard',
        'restriction probable',
        'obstruction',
        'Bronchodilator reversibility'
      ],
      disabledCodeValues: ['default', '3137-7', '3141-9', '72166-2', 'BR'],
      filters: {
        mode2: ['FEV1/FVC', 'FEV1/VC', 'FVC', 'FEV1']
      }
    };
  },
  methods: {
    disableOnMode,
    createShortText,
    getDataFromTableIndex(leftIndex, topIndex) {
      const item = this.compareData.find(
        item => item.rowNumber === leftIndex && item.colNumber === topIndex
      );
      return item;
    }
  },
  computed: {
    jsonObject() {
      return jp.value(this.fhir, 'resource') || '';
    },
    setLeftColumn() {
      const leftColumn = [];
      if (jp.value(this.fhir, 'resource.component')) {
        const component = jp.value(this.fhir, 'resource.component');
        component.map(item => {
          if (item.code.coding) {
            item.code.coding.map(itemCoding => {
              if (
                itemCoding.display &&
                !this.disabledDisplayValues.includes(itemCoding.display) &&
                !this.disabledCodeValues.includes(itemCoding.code)
              ) {
                leftColumn.push(itemCoding.display);
              }
            });
          }
        });
      }
      let uniqueLeftColumn = [...new Set(leftColumn)];
      if (this.mode === 2) {
        uniqueLeftColumn = uniqueLeftColumn.filter(item =>
          this.filters.mode2.includes(item)
        );
      }
      if (this.filterMmts !== null && this.filterMmts.length > 0) {
        uniqueLeftColumn = uniqueLeftColumn.filter(item =>
          this.filterMmts.includes(item)
        );
      }
      return uniqueLeftColumn;
    },
    setTopColumn() {
      const topColumn = [];
      if (jp.value(this.fhir, 'resource.component')) {
        const component = jp.value(this.fhir, 'resource.component');
        component.map(item => {
          if (
            item.code &&
            item.code.text &&
            !this.disabledDisplayValues.find(
              itemDisplay => itemDisplay === item.code.coding[0].display
            )
          ) {
            topColumn.push(item.code.text);
          }
        });
      }
      return [...new Set(topColumn)];
    },
    valueTopLeftColumn() {
      const values = [];
      if (jp.value(this.fhir, 'resource.component')) {
        const component = jp.value(this.fhir, 'resource.component');
        component.map(item => {
          const obj = {};
          obj.text = '';
          obj.display = '';
          obj.object = {};
          obj.rowNumber = -99;
          obj.colNumber = -99;
          if (item.code && item.code.text) {
            obj.text = item.code.text;
          }
          if (item.code.coding) {
            item.code.coding.map(itemCoding => {
              if (itemCoding.display) {
                obj.display = itemCoding.display;
              }
            });
          }
          obj.object = item;
          values.push(obj);
        });
      }
      return values;
    },
    compareData() {
      if (this.valueTopLeftColumn) {
        this.valueTopLeftColumn.map(item => {
          this.setLeftColumn.map((itemLeftColumn, indexLeftColumn) => {
            if (item.display === itemLeftColumn) {
              item.rowNumber = indexLeftColumn;
            }
          });
          this.setTopColumn.map((itemTopColumn, indexTopColumn) => {
            if (item.text === itemTopColumn) {
              item.colNumber = indexTopColumn;
            }
          });
        });
      }
      let compared = this.valueTopLeftColumn;
      if (this.mode === 2) {
        compared = this.valueTopLeftColumn.filter(item =>
          this.filters.mode2.includes(item.display)
        );
      }
      return compared;
    },
    getAttachments() {
      const attachments = [];
      if (jp.value(this.fhir, 'resource.component')) {
        const component = jp.value(this.fhir, 'resource.component');
        component.map(item => {
          if (item.valueAttachment) {
            attachments.push(item.valueAttachment);
          }
        });
      }
      return attachments;
    },
    comment() {
      if (jp.value(this.fhir, 'resource.comment')) {
        return jp.value(this.fhir, 'resource.comment');
      } else {
        return null;
      }
    },
    infoPatient() {
      const patient = [];
      if (jp.value(this.fhir, 'resource.component')) {
        const component = jp.value(this.fhir, 'resource.component');
        component.map(item => {
          if (item.code.coding) {
            item.code.coding.map(itemCoding => {
              if (this.disabledDisplayValues.includes(itemCoding.display)) {
                if (itemCoding.display !== 'default') {
                  patient.push(item);
                }
              }
            });
          }
        });
      }
      const uniquePatient = [...new Set(patient)];
      return uniquePatient;
    }
  }
};
</script>

<style scoped lang="scss">
.fhir__component-table {
  margin-bottom: 6px;
  .fhir__component-th-table,
  .fhir__component-row-table {
    cursor: default;
    padding: 5px;
  }
}
.fhir-component__spiro-mask-image-wrapper {
  justify-content: space-around;
  background: #fff;
  width: 100%;
}
.fhir-component__form__form-ul {
  cursor: default;
  .fhir-component__body__row {
    margin: 6px 0;
    b {
      padding: 0;
    }
  }
}
</style>
