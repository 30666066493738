<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div v-if="isValue(context, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('resourcesStructures.MedicationRequest.context')"
        :value="createShortText(context, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="isValue(authoredOn, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('resourcesStructures.MedicationRequest.authoredOn')"
        :value="createShortText(authoredOn, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div
      v-if="isValue(status, [4, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.MedicationRequest.status')"
        :value="createShortText(status, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="isValue(note, 4, mode)" class="fhir-component__body__row">
      <ListElement
        :label="$t('resourcesStructures.MedicationRequest.note')"
        :value="createShortText(note, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="dosageInstruction" class="fhir-component__body__row">
      <span>
        <b>
          {{ $t('resourcesStructures.MedicationRequest.dosageInstructions') }}:
        </b>
      </span>
      <div
        v-for="(item, index) in dosageInstruction"
        :key="index"
        class="fhir-component__body__row fhir-component__body__row--move-10"
      >
        <SingleValue
          v-if="isValue(item.text, 4, mode)"
          class="fhir-component__body__row"
          :label="$t('resourcesStructures.MedicationRequest.prescription')"
          :value="createShortText(item.text, shortText, maxTextLength)"
          :lang="lang"
        />
        <CodeableElement
          v-if="isValue(item.additionalInstruction, 4, mode)"
          class="fhir-component__body__row"
          :label="
            $t('resourcesStructures.MedicationRequest.additionalInstruction')
          "
          :value="
            createShortText(
              item.additionalInstruction,
              shortText,
              maxTextLength
            )
          "
          :lang="lang"
        />
        <CodeableElement
          v-if="isValue(item.route, 4, mode)"
          class="fhir-component__body__row"
          :label="$t('resourcesStructures.MedicationRequest.route')"
          :value="createShortText(item.route, shortText, maxTextLength)"
          :lang="lang"
        />
        <CodeableElement
          v-if="isValue(item.method, 4, mode)"
          class="fhir-component__body__row"
          :label="$t('resourcesStructures.MedicationRequest.method')"
          :value="createShortText(item.method, shortText, maxTextLength)"
          :lang="lang"
        />
        <div
          v-if="isValue(medicationReferenceReference, 3, mode)"
          class="fhir-component__body__row"
        >
          {{ medicationReferenceReference }}
        </div>
        <div
          v-if="isValue(medicationReferenceDisplay, 3, mode)"
          class="fhir-component__body__row"
        >
          {{ medicationReferenceDisplay }}
        </div>
      </div>
    </div>
    <div v-if="isValue(intent, 4, mode)" class="fhir-component__body__row">
      <SingleValue
        :label="$t('resourcesStructures.MedicationRequest.intent')"
        :value="createShortText(intent, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div v-if="validityPeriodStart" class="fhir-component__body__row">
      <b>
        {{ $t('resourcesStructures.MedicationRequest.validityPeriodStart') }}:
      </b>
      {{ validityPeriodStart }}
    </div>
    <div v-if="validityPeriodEnd" class="fhir-component__body__row">
      <b>
        {{ $t('resourcesStructures.MedicationRequest.validityPeriodEnd') }}:
      </b>
      {{ validityPeriodEnd }}
    </div>
    <div v-if="dose" class="fhir-component__body__row">
      <b>{{ $t('resourcesStructures.Medication.dose') }}:</b>
      <span>
        <template v-if="dose.value">
          {{ dose.value }}
        </template>
        <template v-if="dose.unit">
          {{ dose.unit }}
        </template>
      </span>
    </div>
    <div v-if="substitutionAllowed" class="fhir-component__body__row">
      <b>{{ $t('text.substitutionAllowed') }}:</b>
      {{ substitutionAllowed }}
    </div>
    <div
      v-if="medicationCodeableConcept && medicationCodeableConcept.coding"
      class="fhir-component__body__row"
    >
      <div class="fhir-component__list-label">
        <b>{{ $t('text.medicationCodeableConcept') }}:</b>
      </div>
      <ul
        v-for="(item, index) in medicationCodeableConcept.coding"
        :key="`index-${index}-note-medication-codeable-concept`"
        class="fhir-component__list-element"
      >
        <li class="fhir-component__list-element__list-item">
          {{ item.display }}
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <template v-if="medicationAndDosage">
      {{ medicationAndDosage }}
    </template>
    <MiniMode v-else :lang="lang" :resource="resource" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment } from '../utils/time.utils';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import ListElement from '../ListElement/ListElement';
import CodeableElement from '../CodeableElement/CodeableElement';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'MedicationRequest',
  components: {
    FhirHeader,
    SingleValue,
    ListElement,
    CodeableElement,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    medicationAndDosage() {
      const d = this.dosageInstructionText;
      return `${this.medicationReferenceDisplay}${
        d.trim().length > 0 ? ` (${d})` : ''
      }`;
    },
    subject() {
      return jp.value(this.fhir, 'resource.subject.display') || '';
    },
    context() {
      return jp.value(this.fhir, 'resource.context.display') || '';
    },
    status() {
      return jp.value(this.fhir, 'resource.status') || '';
    },
    note() {
      return jp.value(this.fhir, 'resource.note') || '';
    },
    dosageInstruction() {
      return jp.value(this.fhir, 'resource.dosageInstruction') || '';
    },
    dosageInstructionText() {
      return jp.value(this.fhir, 'resource.dosageInstruction[*].text') || '';
    },
    dose() {
      return jp.value(this.fhir, 'resource.dosage.dose');
    },
    intent() {
      return jp.value(this.fhir, 'resource.intent') || '';
    },
    medicationReferenceReference() {
      return jp.value(this.fhir, 'resource.medicationReference.reference');
    },
    medicationReferenceDisplay() {
      return jp.value(this.fhir, 'resource.medicationReference.display');
    },
    authoredOn() {
      const time = jp.value(this.fhir, 'resource.authoredOn');
      if (time) {
        return dateTimeMoment(time);
      } else {
        return '';
      }
    },
    validityPeriodStart() {
      const time = jp.value(
        this.fhir,
        'resource.dispenseRequest.validityPeriod.start'
      );
      if (time) {
        return dateTimeMoment(time);
      } else {
        return '';
      }
    },
    validityPeriodEnd() {
      const time = jp.value(
        this.fhir,
        'resource.dispenseRequest.validityPeriod.end'
      );
      if (time) {
        return dateTimeMoment(time);
      } else {
        return '';
      }
    },
    medicationCodeableConcept() {
      return jp.value(this.fhir, 'resource.medicationCodeableConcept');
    },
    substitutionAllowed() {
      const getSubstitution = jp.value(this.fhir, 'resource.substitution');
      if (getSubstitution && getSubstitution['allowed'] != null) {
        if (getSubstitution['allowed']) {
          return this.$t('text.yes');
        } else {
          return this.$t('text.no');
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
