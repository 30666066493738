<template>
  <div>
    <ObservationLaboratory
      v-if="observationCategory === 'laboratory'"
      key="observation-laboratory"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :code-types="codeTypes"
      :resource="resource"
      :json-obj="fhir"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :token="token"
    >
      <slot />
    </ObservationLaboratory>

    <ObservationVitalSigns
      v-else-if="observationCategory === 'vital-signs'"
      key="observation-vital-signs"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :json-obj="fhir"
      :resource="resource"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :token="token"
    >
      <slot />
    </ObservationVitalSigns>

    <ObservationTherapy
      v-else-if="observationCategory === 'therapy'"
      key="observation-epicr-therapy-other"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :code-types="codeTypes"
      :resource="resource"
      :json-obj="fhir"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :token="token"
    >
      <slot />
    </ObservationTherapy>

    <ObservationVolumeBrain
      v-else-if="
        observationCategory === 'imaging' &&
          observationCode.trim() === 'brain-analysis'
      "
      key="observation-volume-brain"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :code-types="codeTypes"
      :resource="resource"
      :json-obj="fhir"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :token="token"
    >
      <slot />
    </ObservationVolumeBrain>
    <ObservationImaging
      v-else-if="observationCategory === 'imaging'"
      key="observation-imaging"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :code-types="codeTypes"
      :resource="resource"
      :json-obj="fhir"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :token="token"
    >
      <slot />
    </ObservationImaging>
    <div
      v-else-if="isSpiroBronchodilatorReversibility"
      :class="{ 'spiro-grid': mode > 0 }"
    >
      <ObservationSpirometry
        key="observation-spirometria-br"
        :active-unit="true"
        :class="{ 'spiro-span': mode > 0 }"
        :show-tags="showTags"
        :edit-tags="editTags"
        :mode="mode"
        :code-types="codeTypes"
        :resource="resource"
        :json-obj="fhir"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :info-header="infoHeader"
        :info-footer="infoFooter"
        :lang="lang"
        :token="token"
      >
        <slot />
      </ObservationSpirometry>
      <template v-if="mode > 0">
        <ObservationSpirometry
          v-for="(rel, i) in related"
          :key="`observation-spirometria-br-${i}`"
          :active-unit="true"
          :filter-mmts="['FEV1']"
          :show-tags="showTags"
          :edit-tags="editTags"
          :mode="mode"
          :code-types="codeTypes"
          :resource="resource"
          :json-obj="fhir"
          :short-text="shortText"
          :max-text-length="maxTextLength"
          :info-header="infoHeader"
          :info-footer="infoFooter"
          :lang="lang"
          :token="token"
        >
          <slot />
        </ObservationSpirometry>
      </template>
    </div>
    <ObservationSpirometry
      v-else-if="
        observationCategory === 'procedure' &&
          observationCode.trim() === '81459-0'
      "
      key="observation-spirometria"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :code-types="codeTypes"
      :resource="resource"
      :json-obj="fhir"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :token="token"
    >
      <slot />
    </ObservationSpirometry>

    <ObservationOtherTypes
      v-else
      key="observation-other-types"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :code-types="codeTypes"
      :resource="resource"
      :json-obj="fhir"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :token="token"
    >
      <slot />
    </ObservationOtherTypes>

    <!--
    <ObservationEDSS
      v-else-if="(observationCategory === 'therapy' || observationCategory === 'survey' || observationCategory === 'procedure') && observationCode.trim() === 'edss-scale'"
      key="observation-edss-scale"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :mode-zero-with-date="modeZeroWithDate"
      :show-title-and-menu="showTitleAndMenu"
      :display-important="displayImportant"
      :json-obj="jsonObject"
      :query="query"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :active-emit-html="activeEmitHtml"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :show-important-data="showImportantData"
      :lang="lang"
      :access_token="accessToken"
      @fhirHtml="$emit('fhirHtml', $event)"
    >
      <slot />
    </ObservationEDSS>

    <ObservationMmt
      v-else-if="isMmt"
      key="observation-mmt"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :mode-zero-with-date="modeZeroWithDate"
      :show-title-and-menu="showTitleAndMenu"
      :display-important="displayImportant"
      :json-obj="jsonObject"
      :query="query"
      :short-text="shortText"
      :max-text-length="maxTextLength"
      :active-emit-html="activeEmitHtml"
      :mmts="mmts"
      :lang="lang"
      :access_token="accessToken"
      @fhirHtml="$emit('fhirHtml', $event)"
    >
      <slot />
    </ObservationMmt>

    <ObservationECG
      v-else-if="showECGComponent"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :resource="jsonObject.resource"
      :resources="jsonList"
      :access-token="accessToken"
      :query="query"
      :mode-zero-with-date="modeZeroWithDate"
      :show-title-and-menu="showTitleAndMenu"
      :display-important="displayImportant"
      :json-obj="jsonObject"
      :short-text="shortText"
      :active-emit-html="activeEmitHtml"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :access_token="accessToken"
      @fhirHtml="$emit('fhirHtml', $event)"
    >
      <slot />
    </ObservationECG>
    <ObservationPSG
      v-else-if="jsonObject.resource && observationCategory === 'procedure' && observationCode.trim() === '28633-6'"
      key="observation-psg"
      :show-tags="showTags"
      :edit-tags="editTags"
      :mode="mode"
      :resource="jsonObject.resource"
      :query="query"
      :mode-zero-with-date="modeZeroWithDate"
      :show-title-and-menu="showTitleAndMenu"
      :display-important="displayImportant"
      :json-obj="jsonObject"
      :short-text="shortText"
      :active-emit-html="activeEmitHtml"
      :max-text-length="maxTextLength"
      :info-header="infoHeader"
      :info-footer="infoFooter"
      :lang="lang"
      :access_token="accessToken"
      @fhirHtml="$emit('fhirHtml', $event)"
    >
      <slot />
    </ObservationPSG>
    -->
  </div>
</template>

<script>
import jp from 'jsonpath';
import ObservationLaboratory from './ObservationLaboratory';
import ObservationVitalSigns from './ObservationVitalSigns';
import ObservationTherapy from './ObservationTherapy';
import ObservationVolumeBrain from './ObservationVolumeBrain';
import ObservationImaging from './ObservationImaging';
import ObservationSpirometry from './ObservationSpirometry';
import ObservationOtherTypes from './ObservationOtherTypes';
//import ObservationECG from './ObservationECGManager'
//import ObservationMmt from './ObservationMmt'
//import ObservationPSG from './ObservationPSG'
//import ObservationEDSS from './ObservationEDSS'
import mixins from '../utils/mixins';

export default {
  name: 'Observation',
  components: {
    //ObservationEDSS,
    //ObservationMmt,
    //ObservationECG,
    //ObservationPSG,
    ObservationLaboratory,
    ObservationVitalSigns,
    ObservationTherapy,
    ObservationVolumeBrain,
    ObservationImaging,
    ObservationSpirometry,
    ObservationOtherTypes
  },
  mixins: [mixins],
  data() {
    return {
      mmtCodes: ['KTG', 'MMT', 'KTGWAVE', 'MMTWAVE']
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
    isSpiroBronchodilatorReversibility() {
      if (
        this.observationCategory === 'procedure' &&
        this.observationCode.trim() === '81459-0'
      ) {
        // const br = jp.value(this.fhir || {}, `$.component[*].code.coding[?(@.code=='BR')]`)
        const br = jp.query(
          this.fhir || {},
          "$.resource.component[*].code.coding[?(@.code=='BR')]"
        );
        if (br != null && br.length === 1) {
          const related = this.related;
          // return related!=null && related.length === 2
          return related != null && related.length > 0;
          // $.related[*]
        }
      }
      return false;
    },
    related() {
      return jp.query(
        this.fhir || {},
        '$.resource.related[*].target.reference'
      );
    },
    tenant() {
      return jp.value(
        this.fhir || {},
        "$.resource.meta.tag[?(@.system=='urn:tenant')].code"
      );
    },
    observationCategory() {
      const categories = jp.value(
        this.fhir || {},
        "resource.category[*].coding[?(@.system=='http://hl7.org/fhir/observation-category')].code"
      );
      return categories;
    },
    observationCode() {
      const categories = jp.value(
        this.fhir || {},
        'resource.code.coding[*].code'
      );
      return categories;
    },
    isMmt() {
      // return this.observationCategory === 'vital-signs' && this.mmtCodes.indexOf(this.observationCode) !== -1
      return this.mmtCodes.indexOf(this.observationCode) !== -1;
    },
    jsonObject() {
      return this.fhir;
    },
    jsonList() {
      return this.fhirList;
    },
    showECGComponent() {
      return (
        this.jsonObject.resource &&
        this.observationCategory === 'procedure' &&
        this.observationCode.trim() === '34534-8'
      );
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';

.spiro-grid {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
}
.spiro-span {
  grid-column: 1 / span 2;
}
</style>
