export default function getTranslation(translation, translateDictionaryKey) {
  const translate = translation
    .trim()
    .toLowerCase()
    .split(' ')
    .join('')
    .replace(/-/g, '')
    .replace(/_/g, '');

  if (translate) {
    return this.$t(`${translateDictionaryKey}.${translate}`);
  }
  return null;
}
