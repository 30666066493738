<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div
      v-if="category && disableOnMode([3, 10], mode)"
      title="Category"
      class="fhir-component__body__row"
    >
      <CodeableElement
        ref="categoryRef"
        :label="$t('resourcesStructures.Condition.category')"
        :value="category"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div>
    <div
      v-if="severity && disableOnMode([2, 3, 4], mode)"
      title="Severity"
      class="fhir-component__body__row"
    >
      <CodeableElement
        ref="severityRef"
        :label="$t('resourcesStructures.Condition.severity')"
        :value="severity"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div>
    <div
      v-if="code && disableOnMode(2, mode)"
      title="Code"
      class="fhir-component__body__row"
    >
      <CodeableElement
        ref="codeRef"
        :label="$t('resourcesStructures.Condition.code')"
        :value="code"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div>
    <div
      v-if="bodySite && disableOnMode([2, 3, 4], mode)"
      title="Body Site"
      class="fhir-component__body__row"
    >
      <CodeableElement
        ref="bodySiteRef"
        :label="$t('resourcesStructures.Condition.bodySite')"
        :value="bodySite"
        :short-text="shortText"
        :max-text-length="maxTextLength"
        :lang="lang"
      />
    </div>
    <div
      v-if="subject && disableOnMode([2, 3, 4], mode)"
      title="Subject"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="subjectRef"
        :label="$t('resourcesStructures.Condition.conditionSubject')"
        :value="createShortText(subject, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div
      v-if="context && disableOnMode([2, 3, 4], mode)"
      title="Context"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="contextRef"
        :label="'Kontekst'"
        :value="createShortText(context, shortText, maxTextLength)"
        :lang="lang"
      />
    </div>
    <div
      v-if="evidence && disableOnMode([2, 3, 4], mode)"
      title="Evidence"
      class="fhir-component__body__row"
    >
      <div
        v-for="(item, indexEvidence) in evidence"
        :key="indexEvidence"
        class="fhir-component__body__row"
      >
        <div
          v-if="item.detail"
          class="fhir-component__body__row fhir-component__body__row--move-10"
        >
          <span>
            <b>{{ $t('resourcesStructures.Condition.evidence') }}:</b>
          </span>
          <ul
            v-for="(detail, index) in item.detail"
            :key="index"
            class="list-element"
          >
            <li>
              {{ createShortText(detail.display, shortText, maxTextLength) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="assertedDate" class="fhir-component__body__row">
      {{ assertedDate }}
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang" />
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment, dateMoment } from '../utils/time.utils';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import CodeableElement from '../CodeableElement/CodeableElement';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'Condition',
  components: {
    FhirHeader,
    SingleValue,
    CodeableElement,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    category() {
      return jp.value(this.fhir, 'resource.category') || '';
    },
    severity() {
      return jp.value(this.fhir, 'resource.severity') || '';
    },
    code() {
      return jp.value(this.fhir, 'resource.code') || '';
    },
    bodySite() {
      return jp.value(this.fhir, 'resource.bodySite') || '';
    },
    subject() {
      return jp.value(this.fhir, 'resource.subject.display') || '';
    },
    context() {
      return jp.value(this.fhir, 'resource.context.display') || '';
    },
    onsetDateTime() {
      const getDateTime = jp.value(this.fhir, 'resource.onsetDateTime') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    assertedDate() {
      const getDateTime = jp.value(this.fhir, 'resource.assertedDate') || '';
      if (getDateTime) {
        return dateMoment(getDateTime);
      } else {
        return '';
      }
    },
    evidence() {
      return jp.value(this.fhir, 'resource.evidence') || '';
    },
    note() {
      return jp.value(this.fhir, 'resource.note') || '';
    }
  },
  methods: {
    createShortText,
    disableOnMode
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
