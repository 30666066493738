<template>
  <div>
    <slot />
    <span v-if="codeableConceptText">
      {{ createShortText(codeableConceptText, shortText, maxTextLength) }}
    </span>
    <Codings v-if="jsonObj.coding" :json-obj="jsonObj.coding" :lang="lang" />
    <Codings v-else :json-obj="jsonObj" :lang="lang" />
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import Codings from './Codings';
import createShortText from '../utils/createShortText';

export default {
  name: 'CodeableConcept',
  components: { Codings },
  mixins: [mixins],
  props: {
    displayAndCoding: { required: false, default: 'false', type: String },
    shortText: { default: false, type: Boolean },
    maxTextLength: { default: 10000, type: Number }
  },
  computed: {
    codeableConceptText() {
      if (this.jsonObj) {
        return this.jsonObj.text;
      } else {
        return '';
      }
    }
  },
  methods: {
    createShortText
  }
};
</script>

<style scoped></style>
