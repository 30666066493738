<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-important="true"
    >
      <slot />
    </FhirHeader>
    <div
      v-if="status && disableOnMode([3, 10], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="statusRef"
        :label="$t('resourcesStructures.Encounter.status')"
        :value="status"
        :lang="lang"
      />
    </div>
    <div v-if="period">
      <Period
        v-if="period"
        ref="periodRef"
        :fhir="resource"
        :json-obj="period"
        :lang="lang"
      />
    </div>
    <div
      v-if="encounterClass && ![3].includes(parseInt(mode))"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="encounterClassRef"
        :label="$t('resourcesStructures.Encounter.class')"
        :value="encounterClass"
        :lang="lang"
      />
    </div>
    <div
      v-if="participant && ![2, 3].includes(parseInt(mode))"
      class="fhir-component__body__row"
    >
      <span>
        <b>{{ $t('resourcesStructures.Encounter.participant') }}:</b>
      </span>
      <div v-for="(item, index) in participant" :key="index">
        <div v-if="item.type">
          <div v-for="(type, typeIndex) in item.type" :key="typeIndex">
            <CodeableElement :value="type" :lang="lang" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="serviceProvider && ![2, 3].includes(parseInt(mode))"
      class="fhir-component__body__row"
    >
      <SingleValue
        ref="serviceProviderRef"
        :label="$t('labels.serviceProvider')"
        :value="serviceProvider"
        :lang="lang"
      />
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang">
      <Period
        v-if="period"
        inline
        :fhir="resource"
        :json-obj="period"
        :lang="lang"
      />
    </MiniMode>
  </div>
</template>

<script>
import jp from 'jsonpath';
import mixins from '../utils/mixins';
import Period from '../Period/Period';
import SingleValue from '../SingleValue/SingleValue';
import CodeableElement from '../CodeableElement/CodeableElement';
import disableOnMode from '../utils/disableOnMode';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';

export default {
  name: 'Encounter',
  components: {
    FhirHeader,
    SingleValue,
    CodeableElement,
    Period,
    MiniMode
  },
  mixins: [mixins],
  computed: {
    status() {
      return jp.value(this.resource, 'resource.status') || '';
    },
    encounterClass() {
      return jp.value(this.resource, 'resource.class.display') || '';
    },
    period() {
      return jp.value(this.resource, 'resource.period') || '';
    },
    serviceProvider() {
      return jp.value(this.resource, 'resource.serviceProvider.display') || '';
    },
    participant() {
      return jp.value(this.resource, 'resource.participant') || '';
    }
  },
  methods: {
    disableOnMode
  }
};
</script>

<style>
@import '../assets/styles/base.css';
</style>
